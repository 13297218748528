import { fabric } from 'fabric';
import { getAllObjectsInZOrder } from './utils';
import { CUSTOM_ARROW, CUSTOM_ARROW_JSON } from './constants';

const DEFAULT_TRI_FACTOR = 3.5;
const CIRCLE_SIZE = 5;
const CIRCLE_STROKE_WIDTH = 1;
class Arrow {
  obj = null;
  circleSelected = false;
  canvas = null;

  point1 = null;
  point2 = null;

  bufferLineCenter = null;

  setColor = (color) => {
    this.line.set({ stroke: color });
    this.tri.set({ fill: color });
    this.canvas.renderAll();
  }

  groupSelected = () => { //returns boolean of if any object in the arrow is selected
    if (this.canvas.getActiveObjects().length === 1) {
      const obj = this.canvas.getActiveObjects()[0];
      if (obj === this.line || obj === this.circles[0] || obj === this.circles[1] || obj === this.tri) {
        return true;
      }
    } else {
      return false;
    }
  }

  showCircles = () => {
    for (const circ of this.circles) {
      circ.set({ visible: true });
      this.canvas.bringToFront(circ);
    }
    this.scaleCircles();
    this.canvas.renderAll();
  }

  hideCircles = () => {
    for (const circ of this.circles) {
      circ.set({ visible: false });
    }
    this.canvas.renderAll();
  }

  circleSelected = () => { //callback function for when either circle is selected
    if (this.canvas.getActiveObjects().length > 1) {
      this.canvas.setActiveObject(this.line);
    }
  }

  circleDeselected = () => { //callback for when either circle is deselected
    if (!this.groupSelected()) {
      this.hideCircles();
    }
  }

  lineSelected = () => { //callback for when line is selected
    this.setPoints(this.point1, this.point2, true);
    this.showCircles();
  }

  lineDeselected = () => { //callback for when line is deselected
    if (!this.groupSelected()) {
      this.hideCircles();
    }
    const cleanParams = { scaleX: 1, scaleY: 1, flipX: false, flipY: false, skewX: 0, skewY: 0 };
    this.line.set(cleanParams);
    this.circles.forEach((circle) => {
      circle.set(cleanParams);
    });
    this.setPoints(this.point1, this.point2, false);
  }

  setTriangleAngle = () => { //adjusts the triangle of the arrow to point the right way
    const newPoint = { x: this.point2.x - this.point1.x, y: this.point2.y - this.point1.y };
    let angle = Math.atan2(newPoint.y, newPoint.x);
    angle = angle * (180.0 / Math.PI) + 90;
    this.tri.set({ angle: angle });
  }

  //this function is a workaround to improve the line's bounding box
  //It works by setting the line's coordinates to be horizontal and adjusts
  //the line's 'angle' property so it renders correctly

  angleLine = () => {
    const diff = { x: this.point2.x - this.point1.x, y: this.point2.y - this.point1.y };
    const magnitude = Math.sqrt(diff.x * diff.x + diff.y * diff.y) / 2;

    const center = { x: (this.point2.x + this.point1.x) / 2, y: (this.point2.y + this.point1.y) / 2 };
    this.line.set({ left: center.x, top: center.y });
    const angle = Math.atan2(diff.y, diff.x) * (180.0 / Math.PI);
    this.line.set({ x1: center.x - magnitude, y1: center.y, x2: center.x + magnitude, y2: center.y, angle: angle });
    this.line.setCoords();
  }

  // This method updates the endpoint state of this arrow object and rerenders its fabric objects accordingly
  // multiSelected represents whether the object is part of a group selection
  setPoints = (point1, point2, multiSelected = false) => {
    this.point1 = point1;
    this.point2 = point2;
    if (!multiSelected) {
      this.angleLine(); //adjust line's position
    }
    this.circles[0].set({ left: point1.x, top: point1.y }); //set circle to line up with point1
    this.tri.set({ left: this.point2.x, top: this.point2.y }); //set triangle to line up with point2
    const diff = { x: point2.x - point1.x, y: point2.y - point1.y };
    const magnitude = Math.sqrt(diff.x * diff.x + diff.y * diff.y);

    const tempFactor = this.triVisible ? this.triangleFactor : 0;
    diff.x = diff.x * tempFactor * this.strokeWidth / (magnitude * 2);
    diff.y = diff.y * tempFactor * this.strokeWidth / (magnitude * 2);
    //set other circle to line up with tip of triangle
    this.circles[1].set({ left: this.point2.x + diff.x, top: this.point2.y + diff.y });

    this.tri.setCoords();
    this.setTriangleAngle(); //make the triangle point the correct direction

    this.line.setCoords(); //this handles updating the line's top and left coordinates to its center
    this.circles[0].setCoords();
    this.circles[1].setCoords();

    this.bufferLineCenter = { left: this.line.left, top: this.line.top };

  }
  lineMoving = () => { //callback for when the line is moving
    const diff = { x: this.line.left - this.bufferLineCenter.left, y: this.line.top - this.bufferLineCenter.top };
    this.applyMoving(diff);
  }

  triMoving = () => { //callback for when the tri is moving
    const diff = { x: this.tri.left - this.point2.x, y: this.tri.top - this.point2.y };
    this.applyMoving(diff);
  }

  applyMoving(diff) {
    const tempPoint1 = { x: this.point1.x + diff.x, y: this.point1.y + diff.y };
    const tempPoint2 = { x: this.point2.x + diff.x, y: this.point2.y + diff.y };
    this.setPoints(tempPoint1, tempPoint2);
    this.canvas.renderAll();
  }

  onGroupTransform = () => { //this gets called when the line is part of a group that is skewing, scaling, or moving
    const m = this.line.calcTransformMatrix(); //this is the FULL transform matrix for the line including its rotation
    const diff = { x: this.oldPoint2.x - this.oldPoint1.x, y: this.oldPoint2.y - this.oldPoint1.y };
    diff.x = diff.x / 2;
    diff.y = diff.y / 2;
    const magnitude = Math.sqrt(diff.x * diff.x + diff.y * diff.y);

    //{x: magnitude, y: 0} is the completely unstransformed coordinate of the line end-point
    //(keep in mind the line is always horizontal,
    //then angled so it has a better bounding box than if its coordinates were diagonal)

    const newPoint2 = fabric.util.transformPoint({ x: magnitude, y: 0 }, m);
    const newPoint1 = fabric.util.transformPoint({ x: -1 * magnitude, y: 0 }, m);

    this.setPoints(newPoint1, newPoint2, true);
  }

  multiSelected = () => { //initialize buffer variables when group selected
    this.oldPoint1 = { ...this.point1 }; //make a COPY of point1
    this.oldPoint2 = { ...this.point2 }; //make a COPY of point2
  }

  secondCircleMoving = () => { //callback for when the user drags the circle by the arrow point
    const point2 = { x: this.circles[1].left, y: this.circles[1].top };
    const diff = { x: point2.x - this.point1.x, y: point2.y - this.point1.y };
    const magnitude = Math.sqrt(diff.x * diff.x + diff.y * diff.y);
    const tempFactor = this.triVisible ? this.triangleFactor : 0;
    diff.x = diff.x * tempFactor * this.strokeWidth / (magnitude * 2);
    diff.y = diff.y * tempFactor * this.strokeWidth / (magnitude * 2);
    point2.x = point2.x - diff.x;
    point2.y = point2.y - diff.y;
    this.setPoints(this.point1, point2);
    this.canvas.renderAll();
  }

  firstCircleMoving = () => { //callback for when the user drags the circle opposite the arrow point
    const point1 = { x: this.circles[0].left, y: this.circles[0].top };
    this.setPoints(point1, this.point2);
    this.canvas.renderAll();
  }

  makeCircle = (x, y) => { //create a styled fabric circle for resizing the line
    const circle = new fabric.Circle({
      hasBorders: false,
      hasControls: false,
      radius: this.getCircleRadius(),
      strokeWidth: this.getCircleStrokeWidth(),
      stroke: 'rgba(255, 255, 255, 1)',
      fill: 'rgb(255,142,1)',
      originX: 'center',
      originY: 'center',
      zIndex: Number.MAX_SAFE_INTEGER,
      left: x,
      top: y,
      arrowObj: this
    });
    circle.set({ visible: false });
    circle.toObject = function () {
      return null;
    };
    return circle;
  }

  triClicked = () => { //when the triangle is clicked, programmatically select the line
    this.canvas.setActiveObject(this.line);
  }

  makeArrowHead = () => { //factory function for the arrow's point
    this.tri = new fabric.Triangle({
      originX: 'center',
      originY: 'center',
      zIndex: this.zIndex,
      width: this.triangleFactor * this.strokeWidth,
      height: this.triangleFactor * this.strokeWidth,
      left: this.point2.x,
      top: this.point2.y,
      arrowObj: this,
      cannotBeSelected: true,
      hoverCursor: "pointer",
      lockScalingX: true,
      lockScalingY: true
    });
    this.tri.on('mousedown', this.triClicked);
    this.tri.on('moving', this.triMoving);
    this.tri.toObject = function () {
      return null;
    };
    this.canvas.add(this.tri);
    this.setTriangleAngle();
    this.canvas.renderAll();
  }

  setTriangleShown = (triShown) => {
    if (triShown) {
      this.tri.set({ visible: true });
      if (!this.triVisible) { //we have to move the second circle
        const diff = { x: this.point2.x - this.point1.x, y: this.point2.y - this.point1.y };
        const magnitude = Math.sqrt(diff.x * diff.x + diff.y * diff.y);
        diff.x = diff.x * this.triangleFactor * this.strokeWidth / (magnitude * 2);
        diff.y = diff.y * this.triangleFactor * this.strokeWidth / (magnitude * 2);
        this.circles[1].set({ left: this.tri.left + diff.x, top: this.tri.top + diff.y });
      }
    } else {
      if (this.triVisible) { //we have to move the second circle
        this.circles[1].set({ left: this.tri.left, top: this.tri.top });
      }
      this.tri.set({ visible: false });
    }
    this.canvas.renderAll();
    this.triVisible = triShown;
  }

  setThickness = (thickness) => {
    this.strokeWidth = thickness;
    this.line.set({ strokeWidth: thickness });
    this.tri.set({ width: this.triangleFactor * thickness, height: this.triangleFactor * thickness });
    this.canvas.renderAll();
  }

  setTriangleFactor = (triFactor) => {
    this.triangleFactor = triFactor;
    this.tri.set({ width: triFactor * this.strokeWidth, height: triFactor * this.strokeWidth });
    this.canvas.renderAll();
  }

  //custom json serialization for the line object; the line will hold all of this arrow object's information
  lineToJson = () => {
    return {
      customObj: true,
      type: CUSTOM_ARROW_JSON,
      coords: { x1: this.point1.x, y1: this.point1.y, x2: this.point2.x, y2: this.point2.y },
      strokeWidth: this.line.strokeWidth,
      triangleFactor: this.triangleFactor,
      triVisible: this.triVisible,
      color: this.line.stroke,
      zIndex: this.zIndex
    };
  }

  constructor(coords, canvas, zIndex, strokeWidth = 3.5, triangleFactor = DEFAULT_TRI_FACTOR) {
    this.point1 = { x: coords.x1, y: coords.y1 };
    this.point2 = { x: coords.x2, y: coords.y2 };
    this.canvas = canvas;
    this.triangleFactor = triangleFactor;
    this.strokeWidth = strokeWidth;
    this.zIndex = zIndex;
    
    const line = new fabric.Line([coords.x1, coords.y1, coords.x2, coords.y2],
      {
        stroke: 'black',
        strokeWidth: strokeWidth,
        originX: 'center',
        originY: 'center',
        zIndex: this.zIndex,
        customLine: this, //custom field for accessing this class
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
        hasBorders: false,
        arrowObj: this,
        padding: 6, //to make it easier to select the line
        hoverCursor: "pointer",
      });
    line.toObject = this.lineToJson;

    line.on('selected', this.lineSelected);
    line.on('deselected', this.lineDeselected);
    line.on('moving', this.lineMoving);
    this.bufferLineCenter = { left: line.left, top: line.top };

    const circle1 = this.makeCircle(line.x1, line.y1);
    circle1.on('selected', this.circleSelected);
    circle1.on('moving', this.firstCircleMoving);
    circle1.on('deselected', this.circleDeselected);

    const circle2 = this.makeCircle(line.x2, line.y2);
    circle2.on('selected', this.circleSelected);
    circle2.on('moving', this.secondCircleMoving);
    circle2.on('deselected', this.circleDeselected);

    canvas.add(line);
    canvas.add(circle2);
    canvas.add(circle1);

    this.circles = [circle1, circle2];
    this.line = line;
    this.angleLine(); //fix line bounding box
    this.makeArrowHead();
    this.triVisible = true;

    const tempArr = [this.line, this.circles[0], this.circles[1], this.tri];
    const selectionGroup = [this.line, this.tri];
    tempArr.forEach((obj) => {
      obj.deletionGroup = tempArr; //custom field so when one object is deleted, all get deleted
      obj.selectionGroup = selectionGroup;
      obj.customType = CUSTOM_ARROW;
    });
  }

  getCircleRadius = () => {
    return CIRCLE_SIZE / this.canvas.getZoom();
  }
  getCircleStrokeWidth = () => {
    return CIRCLE_STROKE_WIDTH / this.canvas.getZoom();
  }

  scaleCircles = () => {
    this.circles.forEach((c) => {
      c.set({ radius: this.getCircleRadius(), strokeWidth: this.getCircleStrokeWidth() });
      c.setCoords();
    });
  }

  getObjAbove = () => {
    const realObjs = getAllObjectsInZOrder(this.canvas);
    const startIndex = Math.max(realObjs.indexOf(this.tri), realObjs.indexOf(this.line));
    //maybe error check for if startIndex === -1
    for (let i = startIndex + 1; i < realObjs.length; i++) {
      const other = realObjs[i];
      if (other.intersectsWithObject(this.line) || other.intersectsWithObject(this.tri)) {
        return other;
      }
    }
    return null;
  }

  bringForward = () => {
    const above = this.getObjAbove();
    if (!above) {
      return;
    }
    const low = this.line.zIndex;
    const high = above.zIndex;
    let allAbove = getAllObjectsInZOrder(this.canvas);
    allAbove = allAbove.filter(obj => obj.zIndex > low && obj.zIndex <= high);
    allAbove.forEach((obj) => {
      obj.zIndex = obj.zIndex - 1;
    });
    this.tri.zIndex = high;
    this.line.zIndex = high;
  }

  getObjBelow = () => {
    const realObjs = getAllObjectsInZOrder(this.canvas);
    const startIndex = Math.min(realObjs.indexOf(this.tri), realObjs.indexOf(this.line));
    //maybe error check for if startIndex === -1
    for (let i = startIndex - 1; i >= 0; i--) {
      const other = realObjs[i];
      if (other.intersectsWithObject(this.line) || other.intersectsWithObject(this.tri)) {
        return other;
      }
    }
    return null;
  }

  sendBackwards = () => {
    const below = this.getObjBelow();
    if (!below) {
      return;
    }
    const high = this.line.zIndex;
    const low = below.zIndex;
    let allBelow = getAllObjectsInZOrder(this.canvas);
    allBelow = allBelow.filter(obj => obj.zIndex >= low && obj.zIndex < high);
    allBelow.forEach((obj) => {
      obj.zIndex = obj.zIndex + 1;
    });
    this.tri.zIndex = low;
    this.line.zIndex = low;
  }

}

export default Arrow;
