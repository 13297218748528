import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import * as RectangleTool from './tools/rectangle_tool.js';
import * as PolygonTool from './tools/polygon_tool.js';
import * as CircleTool from './tools/circle_tool.js';
import * as TextTool from './tools/text_tool.js';
import * as ArrowTool from './tools/arrow_tool';
import classNames from 'classnames';
import CustomCanvas from './utils/custom_canvas';
import PanButtons from './pan_buttons';
import ZoomButtons from './zoom_buttons';
import { setTextureSize, getCanvasContainerSize, changeCanvasSize, enableObjectSelection,
  disableObjectSelection, deleteObjects, getCanvasJson, loadFromJson } from './utils/utils';
import { selectionChanged, setJsonState, setTextEdit, setShowSpinner, setCanvas, setCanvasZoom, setTool,
  setBrightness, setContrast, setSaturation, setSharpen, setDrawing } from './reducers/actions';
import * as PanZoom from './tools/pan_zoom_tool';
import { POLY_TOOL, SELECT_TOOL } from './utils/constants';
import './styling.css';
import UndoRedo from './undo_redo.js';


var json = null;

class CanvasComponent extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    image_url: PropTypes.string,
    jsonState: PropTypes.shape({
      future: PropTypes.array,
      past: PropTypes.array,
      present: PropTypes.object
    }),
    photoEditState: PropTypes.string,
    photoOriginalUrl: PropTypes.string,
    popupOpen: PropTypes.bool,
    selected: PropTypes.arrayOf(PropTypes.instanceOf(fabric.Object)),
    selectionChanged: PropTypes.func,
    setBrightness: PropTypes.func,
    setCanvas: PropTypes.func,
    setCanvasZoom: PropTypes.func,
    setContrast: PropTypes.func,
    setDrawing: PropTypes.func,
    setJsonState: PropTypes.func,
    setSaturation: PropTypes.func,
    setSharpen: PropTypes.func,
    setShowSpinner: PropTypes.func,
    setTextEdit: PropTypes.func,
    setTool: PropTypes.func,
    textIsEditing: PropTypes.bool,
    tool: PropTypes.string
  }

  setDefaultObjectStyles = () => {
    fabric.Object.prototype.set({
      transparentCorners: true,
      borderColor: '#000000',
      cornerColor: '#000000',
      cornerSize: 12,
      padding: 0
    });
  }

  constructor(props) {
    super(props);
    this.canvas = null;
    this.newPolygon = false;
    this.multiObjects = false;
    this.multiObjGroup = [];

    this.setDefaultObjectStyles();
  }

  relativeMouseCoords = (options) => { // get correct x and y for canvas drawing
    return this.canvas.getPointer(options.e);
  }

  mouseDownListener = (options) => {

    PanZoom.panMouseDown.call(this, options);
    RectangleTool.rectMouseDown.call(this, options);
    PolygonTool.polyMouseDown.call(this, options);
    TextTool.textMouseDown.call(this, options);
    ArrowTool.arrowMouseDown.call(this, options);
    CircleTool.circMouseDown.call(this, options);

  }

  mouseUpListener = (options) => {

    PanZoom.panMouseUp.call(this, options);
    RectangleTool.rectMouseUp.call(this, options); // could be rectMouseUp.bind(this)(options)
    TextTool.textMouseUp.call(this, options); // could be rectMouseUp.bind(this)(options)
    ArrowTool.arrowMouseUp.call(this, options);
    CircleTool.circMouseUp.call(this, options);
    this.resetStates();
  };

  mouseMoveListener = (options) => {

    PanZoom.panMouseMove.call(this, options);
    RectangleTool.rectMouseMove.call(this, options);
    TextTool.textMouseMove.call(this, options);
    PolygonTool.polyMouseMove.call(this, options);
    ArrowTool.arrowMouseMove.call(this, options);
    CircleTool.circMouseMove.call(this, options);

  }

  setBackground = () => {
    if (!(this.props.image_url || this.props.photoOriginalUrl)) {
      return;
    }
    const url = this.props.photoOriginalUrl ? this.props.photoOriginalUrl : this.props.image_url;
    fabric.Image.fromURL(url, (img) => {

      let textureSize = Math.max(img.width, img.height);
      textureSize = Math.min(textureSize, 8800);
      setTextureSize.call(this, this.canvas, textureSize);

      //Scale image to width of container
      img.scaleToWidth(getCanvasContainerSize().width);

      //Readjust Canvas size to size of image
      this.canvas.setWidth(img.getScaledWidth());
      this.canvas.setHeight(img.getScaledHeight());
      this.previousCanvasWidth = img.getScaledWidth();
      this.previousCanvasHeight = img.getScaledHeight();

      this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas));

      //Only creates a new json state on the first time loading the image - else pulls state from redux provider
      if (this.props.jsonState.present.json === null) {
        this.jsonReduxUpdater();
      }
      this.resize();
    }, { crossOrigin: 'anonymous' });
  };

  selectionChanged = () => {
    this.canvas.renderAll(); //fixes bug with clicking and dragging to select an object not showing object's controls
    this.props.selectionChanged(this.canvas.getActiveObjects());
    const objs = this.canvas.getActiveObjects();
    if (objs.length > 1) {
      for (const obj of objs) {
        if (obj.customLine) {
          obj.customLine.hideCircles();
          obj.customLine.multiSelected();
          this.canvas.getActiveObject().on('moving', obj.customLine.onGroupTransform);
          this.canvas.getActiveObject().on('scaling', obj.customLine.onGroupTransform);
          this.canvas.getActiveObject().on('skewing', obj.customLine.onGroupTransform);
          this.canvas.getActiveObject().on('rotating', obj.customLine.onGroupTransform);
        } else if (obj.polyObj) {
          obj.polyObj.setCirclesVisible(false);
        }
      }
    }

    this.canvas.defaultCursor = 'initial';
  }

  changeCanvasSize = (prevWidth, prevHeight) => {
    changeCanvasSize.call(this, prevWidth, prevHeight, this.canvas);
  }

  //Keyboard event key up listener
  keyUpListener = (event) => {
    //Disables multi object creation
    if (event.key === "Shift") {
      this.multiObjects = false;
    }
    //Disables mouse panning on key up
    if (event.altKey === false && this.panning) {
      this.panning = false;
      this.canvas.setCursor("initial");
      this.canvas.defaultCursor = "initial";
      enableObjectSelection(this.props.canvas);
    }

  }

  //Keyboard event key down listener
  keyDownListener = (event) => {
    //Handles object deletion within the canvas
    if (event.key === "Backspace" || event.key === "Delete") {
      //Handles multiple object deletion
      if (this.props.selected.length > 1) {
        if (this.props.textIsEditing) {
          return;
        } else {
          this.deleteObjects();
        }

      } else { //handles single object deletion
        if (this.props.selected.length === 1) {
          //Select the first and only member
          const selected = this.props.selected[0];

          //Handles textbox deletion a little bit differently due to backspacing in text
          if (selected.get("type") === ("textbox") || selected.isType("text")) {
            if (this.props.textIsEditing) {
              return;
            } else {
              this.deleteObjects();
            }

          } else if (this.props.popupOpen === true) { // don't let delete key work with color picker popup open
            return;
          } else {
            this.deleteObjects();
          }
        }
      }
    } else if (event.shiftKey) { //Enables multi object creation
      this.multiObjects = true;
    } else if (event.altKey === true) { //Enables mouse panning in the canvas
      this.panning = true;
      this.canvas.defaultCursor = "move";
      this.canvas.setCursor("move");
      disableObjectSelection(this.props.canvas, this.canvas.defaultCursor);

    }
  }

  deleteObjects = () => {
    deleteObjects.call(this);
  };

  messageHandler = (m) => {
    // listen for messages telling us the parent window has resized
    // if we use resize as a callback on window resize events from here
    // it will run whenever the iframe is dynamically resized to allow content to fit
    // creating extremely glitchy callback loops
    // alternatively, we could still use the window resize event, but track if the horizontal size changed
    if (m.data.parentResize) {
      this.resize();
    }
  }

  resize = () => {
    changeCanvasSize.call(this, this.previousCanvasWidth, this.previousCanvasHeight, this.canvas);
    this.previousCanvasHeight = this.canvas.height;
    this.previousCanvasWidth = this.canvas.width;
  }

  loadDim = () => {
    // janky way of handling first object drawn bug
    changeCanvasSize.call(this, this.previousCanvasWidth, this.previousCanvasHeight, this.canvas);
    this.previousCanvasHeight = this.canvas.height;
    this.previousCanvasWidth = this.canvas.width;
  }

  jsonReduxUpdater = () => {
    this.getCanvasJson();
    this.props.setJsonState(json);
  }

  getCanvasJson = () => {
    json = getCanvasJson.call(this);
  }

  resetStates = () => {
    if (!this.canvas.getActiveObject()) {
      this.props.setTextEdit(false);
    }
  }

  componentDidMount() {
    this.props.setShowSpinner(false);

    if (this.canvas) {
      this.canvas.dispose();
    }

    this.canvas = new CustomCanvas('photo_editor_canvas', {
      preserveObjectStacking: true
    });

    document.addEventListener("keydown", this.keyDownListener, false);
    document.addEventListener("keyup", this.keyUpListener, false);

    this.canvas.renderAll();

    this.props.setCanvas(this.canvas);
    this.canvas.renderAll();

    this.canvas.on('mouse:down', this.mouseDownListener);
    this.canvas.on('mouse:up', this.mouseUpListener);
    this.canvas.on('mouse:move', this.mouseMoveListener);
    this.canvas.on('mouse:wheel', o => PanZoom.panMouseWheel.call(this, o));

    this.canvas.on('object:modified', this.jsonReduxUpdater);

    this.canvas.on('selection:created', this.selectionChanged);
    this.canvas.on('selection:updated', this.selectionChanged);
    this.canvas.on('selection:cleared', this.selectionChanged);

    this.canvas.on('text:editing:entered', () => {
      this.props.setTextEdit(true);
    });

    this.canvas.on('text:editing:exited', () => {
      this.props.setTextEdit(false);
    });

    //window.addEventListener("resize", this.resize);
    window.addEventListener("message", this.messageHandler);
    window.addEventListener("load", this.loadDim);

    const len = this.props.jsonState.past.length;

    // Allow continuous editing even after modal is closed
    if (len > 1) {
      //prep json to refer to last state available
      const json = this.props.jsonState.present;
      loadFromJson.call(this, json, null, this.canvas, this.firstLoadCallback);
      this.canvas.renderAll();
    } else if (this.props.photoEditState) { //if the photo has an edit state saved in the database
      const json = JSON.parse(this.props.photoEditState);
      loadFromJson.call(this, json, null, this.canvas, this.firstLoadCallback);
      this.canvas.renderAll();
    } else {
      this.setBackground();
    }
  }

  firstLoadCallback = (w, h) => {
    this.previousCanvasWidth = w;
    this.previousCanvasHeight = h;
  }

  componentDidUpdate() {
    if (this.props.tool === POLY_TOOL && this.newPolygon === false) {
      PolygonTool.setUpPolyVars.call(this);
    }
  }

  //Cleaning up listeners
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDownListener, false);
    document.removeEventListener("keydown", this.keyDownListener, true);
    document.removeEventListener("keyup", this.keyUpListener, false);
    document.removeEventListener("keyup", this.keyUpListener, true);
    //window.removeEventListener("resize", this.resize, true);
    //window.removeEventListener("resize", this.resize, false);
    window.removeEventListener("message", this.messageHandler, false);
    window.removeEventListener("message", this.messageHandler, true);

    window.removeEventListener("load", this.loadDim, true);
    window.removeEventListener("load", this.loadDim, false);
    //Clear the selection
    this.props.selectionChanged([]);
    //Reset tool to Select
    this.props.setTool(SELECT_TOOL);
    //Remove the canvas
    //document.getElementById("canvas_hold").remove();
  }

  canvasHolderClassName = () => {
    return classNames("canvas-wrapper apply-font clearfix position-relative h-100 overflow-hidden",
      { "d-none": this.props.showSpinner });
  }

  renderSpinner = () => {
    /*
    if (this.props.showSpinner) {
      return (
        // TODO- replace with actual spinner
        <p>[loading spinner]</p>
      );
    }*/
    return null;
  }

  render() {
    return (
      <div className="h-100">
        {this.renderSpinner()}
        <div className={this.canvasHolderClassName()} id="canvas_hold">
          <ZoomButtons />
          <div className="undo-redo-container">
            <UndoRedo />
          </div>
          <PanButtons />
          <div className="canvas-float h-100 w-100">
            <canvas style={{background: 'transparent'}} id="photo_editor_canvas" />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    selected: state.selected,
    lastColor: state.lastColor,
    jsonState: state.jsonState,
    canvas: state.canvas,
    drawing: state.drawing,
    activeFontFamily: state.activeFontFamily,
    fontSize: state.fontSize,
    text: state.text,
    popupOpen: state.popupOpen,
    showSpinner: state.showSpinner,
    textIsEditing: state.textIsEditing,
  };
}

const mapDispatchToProps = {
  selectionChanged,
  setJsonState,
  setTextEdit,
  setShowSpinner,
  setCanvas,
  setCanvasZoom,
  setTool,
  setBrightness,
  setContrast,
  setSaturation,
  setSharpen,
  setDrawing
};

export default connect(mapStateToProps, mapDispatchToProps)(CanvasComponent);
