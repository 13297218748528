import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Constants from './utils/constants';
import CustomCanvas from './utils/custom_canvas';
import { keepImageOnScreen, setAllCoords } from './utils/utils';
import { setCanvasZoom } from './reducers/actions';
import './zoom_buttons.scss';

class ZoomButtons extends React.Component {

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    canvasZoom: PropTypes.number,
    setCanvasZoom: PropTypes.func,
  }
  zoom = (diff) => {
    let zoom = this.props.canvas.getZoom() * diff;
    if (zoom > Constants.MAX_ZOOM) zoom = Constants.MAX_ZOOM;
    if (zoom < Constants.MIN_ZOOM) zoom = Constants.MIN_ZOOM;
    this.props.canvas.zoomToPoint({ x: this.props.canvas.getWidth() / 2, y: this.props.canvas.getHeight() / 2 }, zoom);

    keepImageOnScreen.call(this);
    setAllCoords.call(this);
    this.props.canvas.renderAll();
    this.props.setCanvasZoom(this.props.canvas.getZoom());
  }

  resetZoom = () => {
    this.props.canvas.setZoom(1);
    this.props.canvas.viewportTransform[4] = 0;
    this.props.canvas.viewportTransform[5] = 0;
    this.props.canvas.renderAll();
    setAllCoords.call(this);
    this.props.setCanvasZoom(this.props.canvas.getZoom());
  }

  getZoomText = (zoomRatio) => {
    return Math.floor(zoomRatio * 100);
  }

  componentWillUnmount() {
    //reset zoom to standard
    this.props.setCanvasZoom(1);
  }
  render() {
    return (
      <div className="zoom-controls p-1">
        <button className="btn btn-primary zoom-button fix-i-offset zoom-button-left-pos"
          type="button" onClick={() => this.zoom(1.25)}
        >
          <i className="fas fa-plus fa-sm" />
        </button>
        <strong className="zoom-percent-text" onClick={this.resetZoom}>
          {this.getZoomText(this.props.canvasZoom)}%
        </strong>
        <button className="btn btn-primary zoom-button fix-i-offset zoom-button-right-pos"
          type="button" onClick={() => this.zoom(0.8)}
        >
          <i className="fas fa-minus fa-sm" />
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    canvas: state.canvas,
    canvasZoom: state.canvasZoom
  };
}

const mapDispatchToProps = { setCanvasZoom };

export default connect(mapStateToProps, mapDispatchToProps)(ZoomButtons);
