import { fabric } from 'fabric';
import { CUSTOM_POLY, CUSTOM_POLY_JSON } from './constants';

const MID_CIRCLE_RADIUS = 4;
const CIRCLE_RADIUS = 6;
const CIRCLE_STROKE_WIDTH = 1;

class Polygon {
  circleUnselectedParams = () => {
    return {
      stroke: 'rgba(255, 255, 255, 1)',
      fill: 'rgb(255,142,1)',
      radius: this.getCircleRadius(false)
    };
  }

  circleSelectedParams = () => {
    return {
      stroke: 'rgba(255, 255, 255, 1)',
      fill: 'rgb(24,92,163)',
      radius: 1.5 * this.getCircleRadius(false)
    };
  }

  calcCenter = () => {
    return this.poly.pathOffset;
  }

  getCircleRadius = (midCircle = false) => {
    if (midCircle) {
      return MID_CIRCLE_RADIUS / this.canvas.getZoom();
    }
    return CIRCLE_RADIUS / this.canvas.getZoom();
  }

  getCircleStrokeWidth = () => {
    return CIRCLE_STROKE_WIDTH / this.canvas.getZoom();
  }

  updatePoints = (newPoints) => {
    const polyProps = this.poly.toObject();
    this.destroyMidCircles();
    this.circles.forEach(c => this.canvas.remove(c));
    this.canvas.remove(this.poly);


    const poly = new fabric.Polygon(newPoints, {
      fill: polyProps.fill,
      stroke: polyProps.stroke,
      strokeWidth: polyProps.strokeWidth,
      originX: 'center',
      perPixelTargetFind: true,
      zIndex: polyProps.zIndex,
      originY: 'center'
    });
    this.canvas.add(poly);
    this.initialize(poly, this.canvas);


  }

  removeCircle = (circle) => {
    if (this.circles.length <= 3) {
      return;
    }
    const newPoints = [];
    for (const c of this.circles) {
      if (c !== circle) {
        newPoints.push({ x: c.left, y: c.top });
      }
    }
    this.updatePoints(newPoints);
    this.canvas.setActiveObject(this.poly);
    this.canvas.fire('object:modified');
    this.onPolyDoubleClick();
  }


  midCircleClicked = (circle) => {
    const i = circle.customIndex;
    const newPoints = [];
    for (const c of this.circles) {
      newPoints.push({ x: c.left, y: c.top });
    }
    newPoints.splice(i, 0, { x: circle.left, y: circle.top });
    this.updatePoints(newPoints);
    this.canvas.setActiveObject(this.poly);
    this.onPolyDoubleClick();
  }

  makeMidCircle = (point, index) => {
    const center = this.calcCenter();
    const goodCoords = this.getTransformedPoint({ x: point.x - center.x,
      y: point.y - center.y },
    this.poly.calcTransformMatrix());
    const c = new fabric.Circle({
      top: goodCoords.y,
      left: goodCoords.x,
      radius: this.getCircleRadius(true),
      originX: 'center',
      originY: 'center',
      strokeWidth: this.getCircleStrokeWidth(),
      fill: 'rgba(255, 255, 255, 1)',
      stroke: 'rgb(255,142,1)',
      visible: this.circles[0].visible,
      zIndex: Number.MAX_SAFE_INTEGER,
      hasControls: false,
      hasBorders: false,
      selectable: false,
      cannotBeSelected: true,
      customType: CUSTOM_POLY,
      polyObj: this,
      referenceCoords: { x: point.x - center.x, y: point.y - center.y }
    });
    c.toObject = function () {
      return null;
    };
    c.on('mousedown', () => this.midCircleClicked(c));
    c.customIndex = index;
    this.canvas.add(c);
    return c;
  }

  makeCircle = (point) => {
    const center = this.calcCenter();
    const goodCoords = this.getTransformedPoint({ x: point.x - center.x,
      y: point.y - center.y },
    this.poly.calcTransformMatrix());
    const c = new fabric.Circle({
      top: goodCoords.y,
      left: goodCoords.x,
      radius: this.getCircleRadius(false),
      originX: 'center',
      originY: 'center',
      strokeWidth: this.getCircleStrokeWidth(),
      stroke: 'rgba(255, 255, 255, 1)',
      fill: 'rgb(255,142,1)',
      zIndex: Number.MAX_SAFE_INTEGER,
      visible: false,
      hasControls: false,
      hasBorders: false,
      customType: CUSTOM_POLY,
      polyObj: this,
      referenceCoords: { x: point.x - center.x, y: point.y - center.y }
    });
    c.on('moving', this.onCircleMove);
    c.on('selected', this.colorSelectedCircle);
    c.on('deselected', () => this.onCircleDeselect(c));
    c.toObject = function () {
      return null;
    };
    c.getDeletionGroup = this.getDeletionGroup;
    this.canvas.add(c);
    return c;
  }

  polyToJson = () => {
    const realPoints = [];
    this.circles.forEach((c) => {
      realPoints.push(this.getTransformedPoint(c.referenceCoords, this.poly.calcTransformMatrix()));
    });
    return {
      customObj: true,
      type: CUSTOM_POLY_JSON,
      points: realPoints,
      fill: this.poly.fill,
      stroke: this.poly.stroke,
      strokeWidth: this.poly.strokeWidth,
      zIndex: this.poly.zIndex
    };
  }

  initPoly = () => {
    this.poly.on('deselected', this.onPolyDeselect);
    this.poly.on('moving', this.onPolyMove);
    this.poly.on('scaling', this.onPolyMove);
    this.poly.on('skewing', this.onPolyMove);
    this.poly.on('rotating', this.onPolyMove);
    this.poly.on('dblclick', this.onPolyDoubleClick);
    this.poly.toObject = this.polyToJson;
    this.poly.setCoords();
    this.poly.getDeletionGroup = this.getDeletionGroup;
    this.poly.customType = CUSTOM_POLY;
    this.poly.polyObj = this;
  }

  setCirclesVisible = (visibility) => {
    [...this.circles, ...this.midCircles].forEach((c) => {
      c.set({ visible: visibility });

    });
    this.scaleCircles();
    this.canvas.renderAll();
  }

  // this method will adjust the style of the selected circle (if there is one)
  colorSelectedCircle = () => {
    if (this.canvas.getActiveObjects().length > 1) {
      this.circles.forEach((c) => {
        c.set(this.circleUnselectedParams());
        c.setCoords();
      });
      this.canvas.renderAll();
    } else if (this.circles.indexOf(this.canvas.getActiveObject()) >= 0) {
      const circle = this.canvas.getActiveObject();
      circle.set(this.circleSelectedParams());
      circle.setCoords();
      this.canvas.renderAll();
    }
  }

  //scales the control circles taking into account canvas zoom
  scaleCircles = () => {
    const midRadius = this.getCircleRadius(true);
    const normalRadius = this.getCircleRadius(false);
    const strokeWidth = this.getCircleStrokeWidth();
    [...this.circles].forEach((c) => {
      c.set({ radius: normalRadius, strokeWidth: strokeWidth });
      c.setCoords();
    });
    [...this.midCircles].forEach((c) => {
      c.set({ radius: midRadius, strokeWidth: strokeWidth });
      c.setCoords();
    });

    this.colorSelectedCircle();
  }

  groupSelected = () => { //returns boolean of if any object in the polygon is selected
    if (this.canvas.getActiveObjects().length === 1) {
      const obj = this.canvas.getActiveObjects()[0];
      if (obj === this.poly) {
        return true;
      }
      for (const c of this.circles) {
        if (obj === c) {
          return true;
        }
      }
    } else {
      return false;
    }
  }

  onCircleDeselect = (c) => {
    this.onPolyDeselect();
    c.set(this.circleUnselectedParams());
    c.setCoords();
    this.canvas.renderAll();
  }


  onPolyDeselect = () => {
    if (!this.groupSelected()) {
      this.setCirclesVisible(false);
      this.poly.set({ hasControls: true, hasBorders: true });
    }
  }

  getTransformedPoint(point, m) {
    return fabric.util.transformPoint(point, m);
  }

  positionCircles = () => {
    const m = this.poly.calcTransformMatrix();
    [...this.circles, ...this.midCircles].forEach((c) => {
      const newCoords = this.getTransformedPoint(c.referenceCoords, m);
      c.set({ left: newCoords.x, top: newCoords.y });
      c.setCoords();
    });
  }

  updatePoly = (points) => {
    const old = this.poly;
    this.canvas.remove(old);
    this.poly = new fabric.Polygon(points);
    this.poly.set({
      fill: old.fill,
      stroke: old.stroke,
      strokeWidth: old.strokeWidth,
      hasControls: old.hasControls,
      zIndex: old.zIndex,
      hasBorders: old.hasBorders,
      perPixelTargetFind: true
    });
    this.canvas.add(this.poly);
    this.initPoly();
    this.circles.forEach(c => c.bringToFront());
  }

  onCircleMove = () => {
    const newPoints = [];
    for (const c of this.circles) {
      newPoints.push({ x: c.left, y: c.top });
    }
    this.updatePoly(newPoints);
    var trueOrigin = this.getTransformedPoint({ x: 0, y: 0 }, this.poly.calcTransformMatrix());
    this.circles.forEach((c) => {
      c.referenceCoords = { x: c.left - trueOrigin.x, y: c.top - trueOrigin.y };
    });

    this.destroyMidCircles();
    this.createmidCircles();
    this.canvas.renderAll();
  }

  onPolyMove = () => {
    this.positionCircles();
    this.canvas.renderAll();
  }

  onPolyDoubleClick = () => {
    this.positionCircles();
    const cleanParams = { scaleX: 1, scaleY: 1, skewX: 0, skewY: 0, flipX: false, flipY: false };
    [...this.circles, ...this.midCircles].forEach(c => c.set(cleanParams));
    this.poly.set({ hasControls: false, hasBorders: false });
    this.setCirclesVisible(true);
    this.canvas.renderAll();
  }

  getMidPoint = (point1, point2) => {
    return { x: (point1.x + point2.x) / 2, y: (point1.y + point2.y) / 2 };
  }

  createmidCircles = () => {
    this.midCircles = [];
    for (let i = 0; i < this.poly.points.length; i++) {
      const point1 = this.poly.points[i];
      const point2 = this.poly.points[((i + 1) % this.poly.points.length)];
      const midPoint = this.getMidPoint(point1, point2);
      this.midCircles.push(this.makeMidCircle(midPoint, i + 1));
    }
  }

  destroyMidCircles = () => {
    this.midCircles.forEach((c) => {
      this.canvas.remove(c);
    });
    this.midCircles = [];
  }

  initialize = (polygon, canvas) => {
    /**@type fabric.Canvas */
    this.canvas = canvas;
    /** @type fabric.Polygon */
    this.poly = polygon;
    this.initPoly();

    this.circles = [];

    this.poly.points.forEach((p) => {
      this.circles.push(this.makeCircle(p));
    });
    this.createmidCircles();

    this.canvas.renderAll();
  }

  constructor(polygon, canvas) {
    this.initialize(polygon, canvas);
  }

  getDeletionGroup = () => {
    return [...this.circles, this.poly];
  }
}

export default Polygon;
