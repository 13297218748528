import React from 'react';
import { connect } from 'react-redux';
import { Popover } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import { updateJsonState } from './utils/utils';
import CustomCanvas from './utils/custom_canvas';
import { setPopupOpen, setJsonState } from './reducers/actions';

class ColorPopover extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    color: PropTypes.string,
    innerRef: PropTypes.func,
    setJsonState: PropTypes.func,
    setPopupOpen: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
  }

  state = { dirty: false }; //We use react state so that changed data can trigger updated rendering
  dirty = false; // BUT setting react state is *asynchronous* and slow,
  // so we need a normal variable for logic based on if the state is dirty to be correct
  // (we can't rely on callback functions when setting state, because componentWillUnmount can be called at any time)
  ogColor = null;

  componentDidMount() {
    this.obj = this.props.canvas.getActiveObject();
    const bufferfields = ['lockMovementX',
      'lockMovementY',
      'lockRotation',
      'lockScalingX',
      'lockScalingY',
      'lockSkewingX',
      'lockSkewingY'];
    this.bufferProps = {};
    const lockedParams = {};
    for (const field of bufferfields) {
      this.bufferProps[field] = this.obj[field];
      lockedParams[field] = true;
    }
    this.obj.set(lockedParams);
    this.ogColor = this.props.color;
    this.props.setPopupOpen(true);
  }

  setDirty(value) {
    this.setState({ dirty: value });
    this.dirty = value;
  }

  render() {
    const {
      canvas,
      color,
      setJsonState,
      setPopupOpen,
      onCancel,
      onChange,
      onSave,
      innerRef,
      jsonState,
      ...popoverProps
    } = this.props;

    return (
      <div>
        <Popover className="chrome" id="chromePop" ref={this.props.innerRef} {...popoverProps} >
          <SketchPicker
            color={this.props.color}
            onChange={this.onColorChange}
          />
        </Popover>
      </div>
    );
  }

  onColorChange = (color, event) => {
    this.setDirty(true);
    updateJsonState.call(this);
    this.props.onChange(color, event);
  }

  componentWillUnmount() {
    this.obj.set(this.bufferProps);
    this.props.setPopupOpen(false);
  }
}

function mapStateToProps(state) {
  return {
    selected: state.selected,
    canvas: state.canvas,
    jsonState: state.jsonState
  };
}

const mapDispatchToProps = { setPopupOpen, setJsonState };


const ConnectedColorPopover = connect(mapStateToProps, mapDispatchToProps)(ColorPopover);

export default ConnectedColorPopover;

export const ColorPopoverWithRef = React.forwardRef((props, ref) => (
  <ConnectedColorPopover
    innerRef={ref} {...props}
  />
));
