import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBringForward } from '@fortawesome/pro-solid-svg-icons';
import { faSendBackward } from '@fortawesome/pro-solid-svg-icons';
import { remove, pullAll, find, findLast } from 'lodash';
import CustomCanvas from './utils/custom_canvas';
import { getAllObjectsInZOrder, getAllRealObjs, updateJsonState } from './utils/utils';
import { CUSTOM_ARROW, CUSTOM_POLY } from './utils/constants';
import { setJsonState } from './reducers/actions';
import './styling.css';
import { Button, ButtonGroup } from 'react-bootstrap';


class LayerControlButtons extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    popupOpen: PropTypes.bool,
    setJsonState: PropTypes.func
  }

  getObjAbove = (obj, groupList) => {
    if (!groupList) {
      groupList = [obj];
    }
    const allObjs = getAllObjectsInZOrder.call(this);
    const currIndex = allObjs.indexOf(obj);
    remove(allObjs, (val, i) => i <= currIndex);
    pullAll(allObjs, groupList);
    return find(allObjs, otherObj => otherObj.intersectsWithObject(obj));
  }

  bringObjForward = (obj, groupList = null) => {
    if (obj.customType === CUSTOM_ARROW) {
      obj.arrowObj.bringForward();
      return;
    } else if (obj.customType === CUSTOM_POLY) {
      obj = obj.polyObj.poly;
    }

    const above = this.getObjAbove(obj, groupList);
    if (!above) {
      return;
    }
    const low = obj.zIndex;
    const high = above.zIndex;
    let allAbove = getAllRealObjs.call(this);
    allAbove = allAbove.filter(o => o.zIndex > low && o.zIndex <= high);
    allAbove.forEach((o) => {
      o.zIndex = o.zIndex - 1;
    });
    obj.zIndex = high;
  }

  getObjBelow = (obj, groupList) => {
    if (!groupList) {
      groupList = [obj];
    }
    const allObjs = getAllObjectsInZOrder.call(this);
    const currIndex = allObjs.indexOf(obj);
    remove(allObjs, (val, i) => i >= currIndex);
    pullAll(allObjs, groupList);
    return findLast(allObjs, otherObj => otherObj.intersectsWithObject(obj));
  }

  sendObjBackwards = (obj, groupList = null) => {

    if (obj.customType === CUSTOM_ARROW) {
      obj.arrowObj.sendBackwards();
      return;
    } else if (obj.customType === CUSTOM_POLY) {
      obj = obj.polyObj.poly;
    }

    const below = this.getObjBelow(obj, groupList);
    if (!below) {
      return;
    }
    const high = obj.zIndex;
    const low = below.zIndex;
    let allBelow = getAllRealObjs.call(this);
    allBelow = allBelow.filter(o => o.zIndex >= low && o.zIndex < high);
    allBelow.forEach((o) => {
      o.zIndex = o.zIndex + 1;
    });
    obj.zIndex = low;
  }
  bringForward = () => {
    if (this.props.canvas.getActiveObjects() == null) {
      return;
    } else if (this.props.canvas.getActiveObjects().length === 1) {
      const obj = this.props.canvas.getActiveObject();
      this.bringObjForward(obj);
    } else {
      this.bringGroupForward();
    }
    this.props.canvas.renderAll();
    this.forceUpdate();
    updateJsonState.call(this);

  }

  getSelectedObjsInOrder = () => {
    let selectedObjs = this.props.canvas.getActiveObjects();
    selectedObjs = selectedObjs.filter(a => a.zIndex);
    let filteredArr = [];
    const seenSet = new Set();
    selectedObjs.forEach((o) => {
      if (!o.customType) {
        filteredArr.push(o);
      } else if (o.customType === CUSTOM_POLY) { // only include one fabric object from a polygon
        if (!seenSet.has(o.polyObj)) {
          seenSet.add(o.polyObj);
          filteredArr.push(o);
        }
      } else if (o.customType === CUSTOM_ARROW) { // only include one fabric object from an arrow
        if (!seenSet.has(o.arrowObj)) {
          seenSet.add(o.arrowObj);
          filteredArr.push(o);
        }
      }
    });
    filteredArr = filteredArr.sort(function (a, b) {
      return a.zIndex - b.zIndex;
    });
    return filteredArr;
  }

  sendGroupBackwards = () => {
    const ogSelected = this.props.canvas.getActiveObjects();
    const orderedSelected = this.getSelectedObjsInOrder();

    // fabric's intersectsWithObject function is broken for objects in groups
    // (and when multiple objects are selected they are grouped)
    // so we programmatically clear the selection, individually send each object backwards then reselect everything
    this.props.canvas.discardActiveObject();
    orderedSelected.forEach((o) => {
      this.sendObjBackwards(o, ogSelected);
    });
    this.props.canvas.setActiveObject(new fabric.ActiveSelection(ogSelected, { canvas: this.props.canvas }));
    this.props.canvas.renderAll();
  }

  bringGroupForward = () => {
    const ogSelected = this.props.canvas.getActiveObjects();
    const orderedSelected = this.getSelectedObjsInOrder();

    // fabric's intersectsWithObject function is broken for objects in groups
    // (and when multiple objects are selected they are grouped)
    // so we programmatically clear the selection, individually move each object forward then reselect everything
    this.props.canvas.discardActiveObject();
    for (let i = orderedSelected.length - 1; i >= 0; i--) {
      this.bringObjForward(orderedSelected[i], ogSelected);
    }
    this.props.canvas.setActiveObject(new fabric.ActiveSelection(ogSelected, { canvas: this.props.canvas }));
    this.props.canvas.renderAll();
  }


  sendBackwards = () => {
    if (this.props.canvas.getActiveObjects() == null) {
      return;
    } else if (this.props.canvas.getActiveObjects().length === 1) {
      const obj = this.props.canvas.getActiveObject();
      this.sendObjBackwards(obj);
    } else { //group selected
      this.sendGroupBackwards();
    }
    this.props.canvas.renderAll();
    this.forceUpdate();
    updateJsonState.call(this);
  };

  isObjAboveGroup = () => {
    const group = this.props.canvas.getActiveObject();
    const selected = this.props.canvas.getActiveObjects();
    const arr = [];
    selected.forEach(o => arr.push(o.zIndex));
    const minIndex = Math.min(...arr);

    for (const obj of getAllObjectsInZOrder.call(this)) {
      if (selected.indexOf(obj) === -1 && obj.intersectsWithObject(group) && obj.zIndex > minIndex) {
        return true;
      }
    }
    return false;
  }

  isObjBelowGroup = () => {
    const group = this.props.canvas.getActiveObject();
    const selected = this.props.canvas.getActiveObjects();
    const arr = [];
    selected.forEach(o => arr.push(o.zIndex));
    const maxIndex = Math.max(...arr);

    for (const obj of getAllObjectsInZOrder.call(this)) {
      if (selected.indexOf(obj) === -1 && obj.intersectsWithObject(group) && obj.zIndex < maxIndex) {
        return true;
      }
    }
    return false;
  }

  getSingleSelectedObj = (obj) => {
    if (!obj.customType) {
      return obj;
    }
    if (obj.customType === CUSTOM_ARROW) {
      return obj.arrowObj.line;
    }
    if (obj.customType === CUSTOM_POLY) {
      return obj.polyObj.poly;
    }
  }

  bringForwardDisabled = () => {
    if (this.props.popupOpen) {
      return true;
    }
    if (this.props.canvas.getActiveObjects().length === 1) {
      const obj = this.getSingleSelectedObj(this.props.canvas.getActiveObject());
      if (this.getObjAbove(obj)) {
        return false;
      }
      return true;
    } else {
      return !this.isObjAboveGroup();
    }
  }

  sendBackwardsDisabled = () => {
    if (this.props.popupOpen) {
      return true;
    }
    if (this.props.canvas.getActiveObjects().length === 1) {
      const obj = this.getSingleSelectedObj(this.props.canvas.getActiveObject());
      if (this.getObjBelow(obj)) {
        return false;
      }
      return true;
    } else {
      return !this.isObjBelowGroup();
    }
  }

  render() {
    const backwardDisabled = this.sendBackwardsDisabled();
    const forwardDisabled = this.bringForwardDisabled();

    return (
      <div className="container-fluid">
        <div className="row flex-column">
          <h5><b>Layer Controls</b></h5>
          <ButtonGroup>
            <Button className={forwardDisabled ? 'unselected-btn' : ''} 
              disabled={forwardDisabled}  name="align-left-btn" 
              size="sm" variant="primary"
              onClick={this.bringForward}
            >
              <FontAwesomeIcon className="fa-lg" icon={faBringForward} />
              <span className="forward-backward-text">Bring Forward</span>
            </Button>
            <Button className={backwardDisabled ? 'unselected-btn' : ''}
              disabled={backwardDisabled} name="align-center-btn" 
              size="sm" variant="primary"
              onClick={this.sendBackwards}
            >
              <FontAwesomeIcon className="fa-lg" icon={faSendBackward} />
              <span className="forward-backward-text">Push Backward</span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    canvas: state.canvas,
    selected: state.selected,
    jsonState: state.jsonState,
    popupOpen: state.popupOpen
  };
}
const mapDispatchToProps = { setJsonState };
export default connect(mapStateToProps, mapDispatchToProps)(LayerControlButtons);
