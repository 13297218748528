import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';

import CanvasComponent from './canvas_component';
import EditWindow from './edit_window';
import ToolBar from './tool_bar';
import FilterMenu from './filter_menu';
import DrawingTools from './drawing_tools';
import UploadWindow from './upload_window';
import { DRAW_MENU, IMAGE_UPLOAD_MENU } from './utils/constants';
import SaveOrCancel from './save_or_cancel';
import './canvas_wrapper.scss';

export class CanvasWrapper extends React.Component {
  static propTypes = {
    image_url: PropTypes.string,
    menu: PropTypes.string,
    photoEditState: PropTypes.string,
    photoOriginalUrl: PropTypes.string,
    selected: PropTypes.arrayOf(PropTypes.instanceOf(fabric.Object)),
    selectionChanged: PropTypes.bool
  }

  selectivelyRender() {
    if (this.props.selected.length >= 1) {
      return (<EditWindow key={this.props.selectionChanged} />);
    }
    else if (this.props.menu === IMAGE_UPLOAD_MENU) {
      return (<UploadWindow />);
    } else if (this.props.menu === DRAW_MENU) {
      return (<DrawingTools />);
    } else {
      return null;
    }
  }
  render() {
    return (
      <div className="d-flex flex-column h-100">
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

        <div className="border-bottom d-flex justify-content-between px-3 py-3">
          <div className="d-flex flex-column justify-content-center">
            <h5 className="font-weight-bold mb-0">Edit Image</h5>
          </div>
          <div>
            <SaveOrCancel />
          </div>
        </div>
        <div className="d-flex overflow-hidden flex-grow-1 photo-editor-body">
          <div className="px-3 py-2">
            <ToolBar />
          </div>
          <div id="canvas-hold" className="border-left border-right flex-grow-1">
            <CanvasComponent
              image_url={this.props.image_url}
              photoEditState={this.props.photoEditState}
              photoOriginalUrl={this.props.photoOriginalUrl}
            />
          </div>
          <div className="px-3 py-2" id="sidebar">
            {this.selectivelyRender()}
            <FilterMenu />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    menu: state.menu,
    jsonState: state.jsonState,
    selectionChanged: state.selectionChanged
  };
}
export default connect(mapStateToProps)(CanvasWrapper);
