import { fabric } from 'fabric';
import Arrow from './arrow';
import Polygon from './polygon';
import { CUSTOM_ARROW, CUSTOM_POLY, CUSTOM_ARROW_JSON, CUSTOM_POLY_JSON } from './constants';

export function updateJsonState() { //useful export; called from CanvasComponent, textEditWindow and SelectionMenu
  const json = getCanvasJson.call(this);
  this.props.setJsonState(json);
}

export function getCanvasJson() {
  //explicitly tell fabric to save the hasControls property of objects
  const j = this.props.canvas.toJSON(['hasControls', 'customObj', 'deletionGroup', 'perPixelTargetFind', 'zIndex']);
  const json = {
    json: j,
    width: this.props.canvas.width,
    height: this.props.canvas.height,
    sliderBright: this.props.jsonState.present.sliderBright,
    sliderSaturation: this.props.jsonState.present.sliderSaturation,
    sliderContrast: this.props.jsonState.present.sliderContrast,
    sliderSharpen: this.props.jsonState.present.sliderSharpen
  };
  return json;
}

export function updateJsonSliderState(brightness, saturation, contrast, sharpen) {
  const j = getCanvasJson.call(this);
  j.sliderBright = brightness;
  j.sliderSaturation = saturation;
  j.sliderContrast = contrast;
  j.sliderSharpen = sharpen;
  this.props.setJsonState(j);
}

export function getCanvasContainerSize() {
  return ({
    width: document.getElementById('canvas_hold').offsetWidth,
    height: document.getElementById('canvas_hold').offsetHeight
  });
}

export function enableObjectSelection(canvas = this.props.canvas) {
  //Enable object selection in canvas - but TAKES into account of custom objects
  for (const obj of canvas._objects) {
    if (obj.cannotBeSelected) {
      //Ignore for objects that ARE NOT supposed to be selected
      continue;
    }
    if (!obj.selectable) {
      obj.set({ hoverCursor: "pointer" });
      obj.selectable = true;
    }
  }
}

export function disableObjectSelection(canvas = this.props.canvas, currentCursor = "initial") {
  //Disables object selection in canvas
  for (const obj of canvas._objects) {
    if (obj.cannotBeSelected) {
      continue;
    }
    if (obj.selectable) {
      obj.set({ hoverCursor: currentCursor });
      obj.selectable = false;
    }
  }
}

export function disableCanvasHoldClicks() {
  const canvasHold = document.getElementById('canvas-hold');
  if(canvasHold) canvasHold.classList.remove('disable-clicks');
};

export function loadFromJson(json, vpTransform = null, canvas = this.props.canvas, callback = null) {
  this.props.setBrightness(json.sliderBright);
  this.props.setSaturation(json.sliderSaturation);
  this.props.setContrast(json.sliderContrast);
  this.props.setSharpen(json.sliderSharpen);

  const newObjArr = [];
  const customObjArr = [];
  for (const obj of json.json.objects) {
    if (obj && !obj.customObj) {
      newObjArr.push(obj);
    } else {
      customObjArr.push(obj);
    }
  }
  const copy = JSON.parse(JSON.stringify(json)); //VERY IMPORTANT TO NOT CHANGE REDUX STATE DIRECTLY
  copy.json.objects = newObjArr;  //VERY IMPORTANT TO NOT CHANGE REDUX STATE DIRECTLY
  canvas.loadFromJSON(copy.json, () => {
    changeCanvasSize.call(this, json.width, json.height, canvas);
    for (const customObj of customObjArr) {
      if (customObj) {
        if (customObj.type === CUSTOM_ARROW_JSON) {
          createCustomArrowObj.call(this, customObj, canvas, json.width, json.height);
        } else if (customObj.type === CUSTOM_POLY_JSON) {
          createCustomPolyObj.call(this, customObj, canvas, json.width, json.height);
        }
      }
    }
    if (vpTransform) {
      canvas.viewportTransform = vpTransform;

      setAllCoords.call(this);
      this.props.setCanvasZoom(canvas.getZoom());
    }

    if (this.props.jsonState.present.json === null) {
      updateJsonState.call(this);
    }

    if (callback) {
      callback(canvas.width, canvas.height);
    }

    const img = canvas.backgroundImage;
    if (img) {
      var textureSize = Math.max(img.width, img.height);
      textureSize = Math.min(textureSize, 8800);
    } else {
      textureSize = 4096;
    }

    setTextureSize.call(this, canvas, textureSize);

    canvas.renderAll();

  });
}

export function createCustomArrowObj(lineProps, canvas = this.props.canvas, prevWidth = null, prevHeight = null) {
  //being extra careful to avoid changing redux state directly
  var coords = JSON.parse(JSON.stringify(lineProps.coords));
  if (prevWidth) {
    var factorX = canvas.height / prevHeight;
    var factorY = canvas.width / prevWidth;
    coords.x1 = coords.x1 * factorX;
    coords.x2 = coords.x2 * factorX;
    coords.y1 = coords.y1 * factorY;
    coords.y2 = coords.y2 * factorY;

  }
  const arrow = new Arrow(coords, canvas, lineProps.zIndex, lineProps.strokeWidth, lineProps.triangleFactor);
  arrow.setTriangleShown(lineProps.triVisible);
  arrow.setColor(lineProps.color);
}

function createCustomPolyObj(polyProps, canvas = this.props.canvas, prevWidth = null, prevHeight = null) {
  //being extra careful to avoid changing redux state directly
  var points = JSON.parse(JSON.stringify(polyProps.points));
  if (prevWidth) {
    var factorX = canvas.height / prevHeight;
    var factorY = canvas.width / prevWidth;
    var newPoints = [];
    points.forEach((p) => {
      newPoints.push({ x: p.x * factorX, y: p.y * factorY });
    });
    points = newPoints;
  }
  const poly = new fabric.Polygon(points, {
    fill: polyProps.fill,
    stroke: polyProps.stroke,
    zIndex: polyProps.zIndex,
    strokeWidth: polyProps.strokeWidth,
    originX: 'center',
    perPixelTargetFind: true,
    originY: 'center'
  });
  poly.setCoords();
  canvas.add(poly);
  new Polygon(poly, this.props.canvas);
}

export function scaleAllObjects(prevWidth, prevHeight, canvas) {
  var factorX = canvas.height / prevHeight;
  var factorY = canvas.width / prevWidth;
  var objects = canvas.getObjects().filter(obj => !obj.customType || obj.customType === CUSTOM_POLY);
  for (var obj of objects) {
    var scaleX = obj.scaleX;
    var scaleY = obj.scaleY;
    var left = obj.left;
    var top = obj.top;

    var tempScaleX = scaleX * factorX;
    var tempScaleY = scaleY * factorY;
    var tempLeft = left * factorX;
    var tempTop = top * factorY;

    obj.set({
      scaleX: tempScaleX,
      scaleY: tempScaleY,
      left: tempLeft,
      top: tempTop
    });
    obj.setCoords();
  }
  canvas.renderAll();
  canvas.calcOffset();
}

export function scaleAllArrows(prevWidth, prevHeight, canvas) {
  const trash = canvas.getObjects().filter(o => o.customType === CUSTOM_ARROW);

  const arrowSet = new Set();
  trash.forEach((o) => {
    if (!arrowSet.has(o.arrowObj)) {
      arrowSet.add(o.arrowObj);
    }
    canvas.remove(o);
  });
  arrowSet.forEach((a) => {
    createCustomArrowObj(a.line.toJSON(), canvas, prevWidth, prevHeight);
  });
}

// TODO: This doesn't properly scale shape stroke widths. Not used and can probably be removed.
// Seems like it was intended to scale the image & canvas objects back to the image's original size, but that's not
// necessary; `canvas.toDataURL` with `multiplier` does the scaling automatically without loss of quality.
export function maxResolution(canvas) { //scale canvas to prepare for saving image
  canvas.discardActiveObject();

  const img = canvas.backgroundImage;
  const prevWidth = canvas.getWidth();
  const prevHeight = canvas.getHeight();
  img.scale(1); //scale image to native resolution
  canvas.setZoom(1); //reset zoom
  canvas.viewportTransform[4] = 0; //reset pan
  canvas.viewportTransform[5] = 0; //reset pan
  canvas.setWidth(img.getScaledWidth());
  canvas.setHeight(img.getScaledHeight());
  scaleAllObjects.call(this, prevWidth, prevHeight, canvas);
  scaleAllArrows.call(this, prevWidth, prevHeight, canvas);
  canvas.calcOffset();
  canvas.renderAll();
  this.props.setCanvasZoom(canvas.getZoom());
}

export function changeCanvasSize(prevWidth, prevHeight, canvas) {
  canvas.discardActiveObject();

  var img = canvas.backgroundImage;
  img.scaleToWidth(getCanvasContainerSize().width);
  canvas.setZoom(1); //reset zoom
  canvas.viewportTransform[4] = 0; //reset pan
  canvas.viewportTransform[5] = 0; //reset pan
  canvas.setWidth(img.getScaledWidth());
  canvas.setHeight(img.getScaledHeight());
  scaleAllObjects.call(this, prevWidth, prevHeight, canvas);
  scaleAllArrows.call(this, prevWidth, prevHeight, canvas);
  canvas.calcOffset();
  canvas.renderAll();
  this.props.setCanvasZoom(canvas.getZoom());
}

export function deleteObjects() {
  if (this.props.canvas.getActiveObjects().length === 0) {
    return;
  }
  if (this.props.canvas.getActiveObjects().length === 1) {
    const selected = this.props.canvas.getActiveObject();
    if (selected.customType === CUSTOM_POLY && selected.__proto__.type === 'circle') {
      if (selected.polyObj.circles.length > 3) {
        selected.polyObj.removeCircle(selected);
        return;
      }
    }
  }
  const toDelete = new Set();
  this.props.canvas.getActiveObjects().forEach((obj) => {
    if (obj.deletionGroup) {
      obj.deletionGroup.forEach((i) => {
        toDelete.add(i);
      });
    } else if (obj.getDeletionGroup) {
      obj.getDeletionGroup().forEach((i) => {
        toDelete.add(i);
      });
    } else {
      this.props.canvas.remove(obj);
    }
  });
  toDelete.forEach((obj) => {
    this.props.canvas.remove(obj);
  });
  this.props.canvas.discardActiveObject(); //this has to get called before renderAll
  this.props.canvas.renderAll();


  updateJsonState.call(this);
}

export function setAllCoords() {
  this.props.canvas.forEachObject((obj) => {
    obj.setCoords();
  });
}

export function keepImageOnScreen() {
  const c = this.props.canvas;
  const m = c.viewportTransform;

  let newPoint = fabric.util.transformPoint({ x: 0, y: 0 }, m);
  newPoint = [newPoint.x, newPoint.y];
  const factor = 0.5;

  if (newPoint[0] > factor * c.getWidth()) {
    c.viewportTransform[4] = factor * c.getWidth();
  }
  if (newPoint[1] > factor * c.getHeight()) {
    c.viewportTransform[5] = factor * c.getHeight();
  }
  newPoint = fabric.util.transformPoint({ x: c.getWidth(), y: c.getHeight() }, m);
  newPoint = [newPoint.x, newPoint.y];
  if (newPoint[0] < (1 - factor) * c.getWidth()) {
    const temp = m[0] * c.getWidth() + m[2] * c.getHeight();
    c.viewportTransform[4] = (1 - factor) * c.getWidth() - temp;
  }
  if (newPoint[1] < (1 - factor) * c.getHeight()) {
    const temp = m[1] * c.getWidth() + m[3] * c.getHeight();
    c.viewportTransform[5] = (1 - factor) * c.getHeight() - temp;
  }
}

export function scaleAllControlCircles() { //this function resizes all circles on lines and polygons based off zoom
  const fixedPolygons = new Set();
  this.props.canvas.forEachObject((obj) => {
    if (obj.customLine) { //for custom lines, only the line has a customLine object (not the circles or triangle)
      obj.customLine.scaleCircles();
    } else if (obj.polyObj && !fixedPolygons.has(obj.polyObj)) {
      fixedPolygons.add(obj.polyObj);
      obj.polyObj.scaleCircles();
    }
  });
}

export function getAllRealObjs(canvas = null) {
  if (!canvas) {
    canvas = this.props.canvas;
  }
  let answer = canvas.getObjects().filter(obj => typeof (obj.toJSON()) !== 'undefined');
  answer = answer.filter(obj => obj.zIndex && obj.zIndex < Number.MAX_SAFE_INTEGER);
  return answer;
}

export function getAllObjectsInZOrder(canvas = null) {
  let allObjs = getAllRealObjs.call(this, canvas);
  allObjs = allObjs.sort(function (a, b) {
    var sortValue = 0, az = a.zIndex || 0, bz = b.zIndex || 0;

    if (az < bz) {
      sortValue = -1;
    } else if (az > bz) {
      sortValue = 1;
    }

    return sortValue;
  });
  return allObjs;
}

export function getMaxZ() {
  const allObjs = getAllRealObjs.call(this);
  let max = 0;
  for (const obj of allObjs) {
    if (obj.zIndex > max) {
      max = obj.zIndex;
    }
  }
  return max;
}

export function setTextureSize(canvas, size) {
  fabric.textureSize = size;
  canvas.filterBackend = fabric.WebglFilterBackend;
  fabric.isWebglSupported(fabric.textureSize);
}
