import undoable from 'redux-undo';
const MAX_UNDO_HISTORY = 100;
const DEFAULT_SLIDER_VAL = 50;
const defaultState = {
  json: null,
  width: null,
  height: null,
  sliderBright: DEFAULT_SLIDER_VAL,
  sliderSaturation: DEFAULT_SLIDER_VAL,
  sliderContrast: DEFAULT_SLIDER_VAL,
  sliderSharpen: 0
};
const jsonState = (state = defaultState, action) => {
  if (action.type === "SET_JSON_STATE") {
    return action.jsonState;
  } else {
    return state;
  }
};

const undoableJsonState = undoable(jsonState, {
  limit: MAX_UNDO_HISTORY
  //filter: distinctState()
  //looks like distinctState is deprecated and applied by default?
});

export default undoableJsonState;