import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CustomCanvas from './utils/custom_canvas';
import { disableObjectSelection } from './utils/utils';
import { setDrawing, setTool } from './reducers/actions';
import { ARROW_TOOL, CIRCLE_TOOL, POLY_TOOL, RECT_TOOL } from './utils/constants';
import './styling.css';

class DrawingTools extends React.Component {
  ////////////////////////////////////////////////////////////////
  // These setTool methods can be moved out of this component
  ///////////////////////////////////////////////////////////
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    setDrawing: PropTypes.func,
    setTool: PropTypes.func,
    tool: PropTypes.string,
  }
  
  setRectangleTool = () => {

    this.props.canvas.selection = false;
    this.props.setTool(RECT_TOOL);
    this.props.canvas.defaultCursor = "crosshair"; //cursor for drawing rectangles
    disableObjectSelection(this.props.canvas, this.props.canvas.defaultCursor);
  }

  setPolygonTool = () => {
    this.props.canvas.selection = false;
    this.props.setTool(POLY_TOOL);
    this.props.canvas.defaultCursor = "crosshair"; //cursor for drawing polygons
    this.props.setDrawing(true);
    disableObjectSelection(this.props.canvas, this.props.canvas.defaultCursor);
  }

  setArrowTool = () => {
    this.props.canvas.selection = false;
    this.props.setTool(ARROW_TOOL);
    this.props.canvas.defaultCursor = "crosshair";
    disableObjectSelection(this.props.canvas, this.props.canvas.defaultCursor);
  }

  setCircleTool = () => {
    this.props.canvas.selection = false;
    this.props.setTool(CIRCLE_TOOL);
    this.props.canvas.defaultCursor = "crosshair";
    disableObjectSelection(this.props.canvas, this.props.canvas.defaultCursor);
  }

  drawingButtonClass = (tool) => {
    return classNames(
      "mr-3 btn btn-primary btn-lg btn-block drawing-tool-button general-button",
      { "active": this.props.tool === tool }
    );
  }

  render() {
    return (
      <div>
        <h4>Drawing Tools:</h4>
        <hr />
        <button className={this.drawingButtonClass(CIRCLE_TOOL)} onClick={this.setCircleTool}>
          <i className="far fa-circle fa-lg"></i>
          <span className="py-0 pl-2">Circle</span>
        </button>
        <button className={this.drawingButtonClass(POLY_TOOL)} onClick={this.setPolygonTool}>
          <i className="fas fa-draw-polygon fa-lg"></i>
          <span className="py-0 pl-2">Polygon</span>
        </button>

        <button className={this.drawingButtonClass(RECT_TOOL)} onClick={this.setRectangleTool}>
          <i className="far fa-square fa-lg"></i>
          <span className="py-0 pl-2">Rectangle</span>
        </button>
        <button className={this.drawingButtonClass(ARROW_TOOL)} onClick={this.setArrowTool}>
          <i className="fas fa-long-arrow-alt-up fa-lg"></i>
          <span className="py-0 pl-2">Arrow</span>
        </button>
        <hr />
        <div>
          <strong>Shift</strong> - Create multiple object(s)
        </div>
        <div>
          <strong>Delete</strong> - Remove object(s)
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    jsonState: state.jsonState
  };
}
const mapDispatchToProps = { setDrawing, setTool };
export default connect(mapStateToProps, mapDispatchToProps)(DrawingTools);
