import React from "react";
import PropTypes from 'prop-types';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { connect } from 'react-redux';
import CustomCanvas from './utils/custom_canvas';
import { loadFromJson } from './utils/utils';
import { setJsonState, setBrightness, setSaturation, setContrast, setSharpen, setCanvasZoom } from './reducers/actions';
import './styling.css';
import './undo_redo_styles.scss';

export class UndoRedo extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    jsonState: PropTypes.shape({
      future: PropTypes.array,
      past: PropTypes.array,
      present: PropTypes.object
    }),
    redo: PropTypes.func,
    setBrightness: PropTypes.func,
    setCanvasZoom: PropTypes.func,
    setContrast: PropTypes.func,
    setJsonState: PropTypes.func,
    setSaturation: PropTypes.func,
    setSharpen: PropTypes.func,
    undo: PropTypes.func,
  }
  undo = () => {
    const len = this.props.jsonState.past.length;
    document.getElementById("canvas-hold").classList.remove('disable-clicks');
    if (len > 0) {
      const json = this.props.jsonState.past[len - 1];
      loadFromJson.call(this, json, this.props.canvas.viewportTransform, this.props.canvas);
      this.props.undo();
    }

  }
  redo = () => {
    const len = this.props.jsonState.future.length;
    document.getElementById("canvas-hold").classList.remove('disable-clicks');
    if (len > 0) {
      const json = this.props.jsonState.future[0];
      loadFromJson.call(this, json, this.props.canvas.viewportTransform, this.props.canvas);
      this.props.redo();
    }
  }

  render() {
    return (
      <span>
        <button name="undo-btn" className="btn btn-primary btn-sm undo-redo-buttons" 
          disabled={this.props.jsonState.past.length <= 1 
            /* 1 not 0, because a useless state of null shows up in the queue*/}
          onClick={this.undo}
        >
          <i className="fas fa-undo fa-sm"></i>
        </button>
        <button name="redo-btn" className="btn btn-primary btn-sm undo-redo-buttons" 
          disabled={this.props.jsonState.future.length === 0} onClick={this.redo}
        >
          <i className="fas fa-redo fa-sm"></i>
        </button>

      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    selected: state.selected,
    lastColor: state.lastColor,
    canvas: state.canvas,
    jsonState: state.jsonState
  };
}

const undo = UndoActionCreators.undo;
const redo = UndoActionCreators.redo;

const mapDispatchToProps = {
  setJsonState,
  setBrightness,
  setSaturation,
  setContrast,
  setSharpen,
  undo,
  redo,
  setCanvasZoom
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoRedo);
