import { fabric } from 'fabric';
import { getMaxZ, updateJsonState, enableObjectSelection, disableObjectSelection } from '../utils/utils';
import Arrow from '../utils/arrow';
import { SELECT_TOOL, ARROW_TOOL } from '../utils/constants';

export function arrowMouseDown(options) {

  if (this.props.tool === ARROW_TOOL && !this.canvas.getActiveObject() && !options.e.altKey) {
    const realCoords = this.relativeMouseCoords(options);
    this.lineStartPoint = { x: realCoords.x, y: realCoords.y };
  }
}

export function arrowMouseMove(options) {
  if (this.props.tool === ARROW_TOOL && this.lineStartPoint) {
    const relCoords = this.relativeMouseCoords(options);
    this.lineEndPoint = { x: relCoords.x, y: relCoords.y };
    if (!this.arrowLine) {
      createTempLine.call(this);
    }
    this.arrowLine.set({ x2: relCoords.x, y2: relCoords.y });
    this.arrowLine.setCoords();

    this.canvas.renderAll();
  }
}

function createTempLine() {
  this.arrowLine = new fabric.Line(
    [this.lineStartPoint.x, this.lineStartPoint.y, this.lineEndPoint.x, this.lineEndPoint.y],
    {
      stroke: 'rgba(0,0,0,20)',
      strokeWidth: 1.1,
      zIndex: Number.MAX_SAFE_INTEGER,
      hoverCursor: 'crosshair',
      originX: 'center',
      originY: 'center',
      fill: 'orange',
      selectable: false
    });
  this.canvas.add(this.arrowLine);
  this.canvas.renderAll();
}

// allow the user to create lines by clicking (without dragging)
function enforceMinLength() {
  const NEW_WIDTH = 35; // length of line created
  const MIN_WIDTH = 8; //threshold for when the user has clicked without dragging
  let tooSmall = false;
  if (!this.lineEndPoint) {
    tooSmall = true;
  } else {
    const diff = { x: this.lineEndPoint.x - this.lineStartPoint.x,
      y: this.lineEndPoint.y - this.lineStartPoint.y };
    const length = Math.sqrt(diff.x * diff.x + diff.y * diff.y);
    if (length < MIN_WIDTH) {
      tooSmall = true;
    }
  }
  if (tooSmall) {
    const newX = this.lineStartPoint.x + NEW_WIDTH < this.canvas.width ?
      this.lineStartPoint.x + NEW_WIDTH :
      this.lineStartPoint.x - NEW_WIDTH;
    this.lineEndPoint = { x: newX, y: this.lineStartPoint.y };
  }
}

export function arrowMouseUp() {
  if (this.props.tool === ARROW_TOOL) {
    
    if (this.arrowLine) {
      // remove the temporary line rendered for drawing the arrow
      this.canvas.setActiveObject(this.arrowLine);
      this.canvas.remove(this.canvas.getActiveObject());
    }

    // allow the user to create a line by clicking (without dragging)
    enforceMinLength.call(this);
    
    // create a custom arrow object
    const coords = {
      x1: this.lineStartPoint.x,
      y1: this.lineStartPoint.y,
      x2: this.lineEndPoint.x,
      y2: this.lineEndPoint.y
    };
    const arrow = new Arrow(coords, this.canvas, getMaxZ.call(this) + 1);
    this.canvas.renderAll();

    this.multiObjGroup.push(arrow.line);

    updateJsonState.call(this);

    if (!this.multiObjects) {
      if (this.multiObjGroup.length === 1) {
        this.canvas.setActiveObject(arrow.line);
      } else {
        const selection = new fabric.ActiveSelection(this.multiObjGroup, {
          canvas: this.canvas
        });

        this.canvas.setActiveObject(selection);
      }

      this.canvas.selection = true;
      this.props.setTool(SELECT_TOOL);
      this.canvas.defaultCursor = "initial";
      this.multiObjGroup = [];
      enableObjectSelection(this.canvas);

    } else {
      //Adds additional objects and set the selection as disabled
      disableObjectSelection(this.canvas, this.canvas.defaultCursor);
    }
    this.canvas.renderAll();
    
    this.arrowLine = null;
    this.lineStartPoint = null;
    this.lineEndPoint = null;
  }
}
