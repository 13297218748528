import React, { useRef, useState } from 'react';
import { Form, Overlay } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ColorPopoverWithRef as ColorPopover } from '../../color_popover';
import './styles.scss';

const ColorPicker = ({ value, disabled, onChange, onOpen, onClose }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  return <div className="color-picker d-flex" ref={ref}>
    <button className="btn btn-primary color-picker-button" disabled={disabled}
      name={'color-btn'}
      style={{ backgroundColor: value }}
      onClick={(event) => {
        onOpen && onOpen();
        setPopoverOpen(true);
        setTarget(event.target);
      }}
    />
    <Form.Control readOnly type="text" value={value} />

    <Overlay
      container={ref.current}
      containerPadding={20}
      rootClose
      placement="bottom"
      // This popperConfig is only needed because without it react-overlay throws an error
      // saying popoverArrowMargins modifier does not have a "fn" property.
      // We coulndn't find real problem of this so just hardcoding it to avoid the error.
      popperConfig={{ modifiers: [{ fn: () => {}, enabled: true, name: 'popoverArrowMargins', phase: 'main' }] }}
      show={popoverOpen}
      onHide={() => {
        setPopoverOpen(false);
        onClose && onClose();
      }}
      target={target}
    >
      <ColorPopover
        color={value}
        onChange={onChange}
      />
    </Overlay>
  </div>;
};

ColorPicker.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
};

export default ColorPicker;
