import { combineReducers } from "redux";
import jsonState from "./jsonState";
import { SELECT_TOOL } from '../utils/constants';

const DEFAULT_SLIDER_VAL = 50;
function tool(state = SELECT_TOOL, action) {
  if (action.type === "SET_TOOL") {
    return action.tool;
  } else {
    return state;
  }
}

function selected(state = [], action) {
  if (action.type === 'SELECTION_CHANGED') {
    return action.selection;
  } else {
    return state;
  }
}

function selectionChanged(state = false, action) {
  if (action.type === 'SELECTION_CHANGED') {
    return !state;
  } else {
    return state;
  }
}

function canvas(state = null, action) {
  if (action.type === "SET_CANVAS") {
    return action.canvas;
  } else {
    return state;
  }
}

function color(state = null, action) {
  if (action.type === "SET_LAST_COLOR") {
    return action.color;
  } else {
    return state;
  }
}

function toBeColored(state = null, action) {
  if (action.type === "SET_TO_BE_COLORED") {
    return action.toBeColored;
  } else {
    return state;
  }
}
function drawing(state = false, action) {
  if (action.type === "SET_DRAWING") {
    return action.drawing;
  } else {
    return state;
  }
}

function sliderBright(state = DEFAULT_SLIDER_VAL, action) {
  if (action.type === "SET_BRIGHTNESS") {
    return action.sliderBright;
  } else if (action.type === "RESET_BRIGHTNESS") {
    return DEFAULT_SLIDER_VAL;
  } else {
    return state;
  }
}

function sliderContrast(state = DEFAULT_SLIDER_VAL, action) {
  if (action.type === "SET_CONTRAST") {
    return action.sliderContrast;
  } else if (action.type === "RESET_CONTRAST") {
    return DEFAULT_SLIDER_VAL;
  } else {
    return state;
  }
}

function sliderSharpen(state = 0, action) {
  if (action.type === "SET_SHARPEN") {
    return action.sliderSharpen;
  } else if (action.type === "RESET_SHARPEN") {
    return 0;
  } else {
    return state;
  }
}

function sliderSaturation(state = DEFAULT_SLIDER_VAL, action) {
  if (action.type === "SET_SATURATION") {
    return action.sliderSaturation;
  } else if (action.type === "RESET_SATURATION") {
    return DEFAULT_SLIDER_VAL;
  } else {
    return state;
  }
}

function activeFontFamily(state = "Times", action) {
  if (action.type === "SET_FONT") {
    return action.activeFontFamily;
  } else {
    return state;
  }
}

function fontSize(state = 20, action) {
  if (action.type === "SET_FONT_SIZE") {
    return action.fontSize;
  } else {
    return state;
  }
}

function text(state = "", action) {
  if (action.type === "SET_TEXT") {
    return action.text;
  } else {
    return state;
  }
}

function menu(state = "NONE", action) {
  if (action.type === "SET_MENU") {
    return action.menu;
  } else if (action.type === "SET_TOOL" && action.tool === SELECT_TOOL) {
    return null;
  } else {
    return state;
  }
}

function canvasZoom(state = 1, action) {
  if (action.type === "SET_CANVAS_ZOOM") {
    if (action.zoom)
      return action.zoom;
    else return state;
  } else {
    return state;
  }
}

function popupOpen(state = false, action) {
  if (action.type === "SET_POPUP_OPEN") {
    return action.popupOpen;
  } else {
    return state;
  }
}

function textIsEditing(state = false, action) {
  if (action.type === "SET_TEXT_EDIT") {
    return action.textIsEditing;
  } else {
    return state;
  }
}

function isBold(state = false, action) {
  if (action.type === "SET_BOLD") {
    return action.isBold;
  } else {
    return state;
  }
}

function isItalic(state = false, action) {
  if (action.type === "SET_ITALIC") {
    return action.isItalic;
  } else {
    return state;
  }
}

function isUnderlined(state = false, action) {
  if (action.type === "SET_UNDERLINE") {
    return action.isUnderlined;
  } else {
    return state;
  }
}

function align(state = 'center', action) {
  if (action.type === "SET_ALIGN") {
    return action.align;
  } else {
    return state;
  }
}

function showSpinner(state = false, action) {
  if (action.type === "SET_SHOW_SPINNER") {
    return action.value;
  } else {
    return state;
  }
}

function photoId(state = null, action) {
  if (action.type === "SET_PHOTO_ID") {
    return action.id
  } else {
    return state;
  }
}

const reducer = combineReducers({
  tool,
  canvas,
  selected,
  color,
  jsonState,
  drawing,
  sliderBright,
  sliderContrast,
  sliderSaturation,
  sliderSharpen,
  toBeColored,
  activeFontFamily,
  fontSize,
  text,
  selectionChanged,
  menu,
  canvasZoom,
  isBold,
  isItalic,
  isUnderlined,
  align,
  popupOpen,
  textIsEditing,
  showSpinner,
  photoId
});
export default reducer;
