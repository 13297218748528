export function setPopupOpen(open) {
  return {
    type: "SET_POPUP_OPEN",
    popupOpen: open
  };
}

export function setSharpen(val) {
  return {
    type: "SET_SHARPEN",
    sliderSharpen: val
  };
}

export function setBrightness(val) {
  return {
    type: "SET_BRIGHTNESS",
    sliderBright: val
  };
}

export function setSaturation(val) {
  return {
    type: "SET_SATURATION",
    sliderSaturation: val
  };
}

export function setContrast(val) {
  return {
    type: "SET_CONTRAST",
    sliderContrast: val
  };
}

export function selectionChanged(currentlySelected) {
  return {
    type: "SELECTION_CHANGED",
    selection: currentlySelected
  };
}

export function setJsonState(json) {
  return {
    type: "SET_JSON_STATE",
    jsonState: json
  };
}

export function setTextEdit(textIsEditing) {
  return {
    type: "SET_TEXT_EDIT",
    textIsEditing: textIsEditing
  };
}

export function setShowSpinner(show) {
  return {
    type: "SET_SHOW_SPINNER",
    value: show
  };
}

export function setCanvas(canvas) {
  return {
    type: "SET_CANVAS",
    canvas: canvas
  };
}

export function setTool(tool) {
  return {
    type: "SET_TOOL",
    tool: tool
  };
}

export function resetBrightness() {
  return {
    type: "RESET_BRIGHTNESS"
  };
}

export function resetSaturation() {
  return {
    type: "RESET_SATURATION"
  };
}

export function resetContrast() {
  return {
    type: "RESET_CONTRAST"
  };
}

export function resetSharpen() {
  return {
    type: "RESET_SHARPEN"
  };
}

export function setCanvasZoom(value) {
  return {
    type: "SET_CANVAS_ZOOM",
    zoom: value
  };
}

export function setLastColor(color) {
  return {
    type: "SET_LAST_COLOR",
    color: color
  };
}

export function setText(value) {
  return {
    type: "SET_TEXT",
    text: value
  };
}

export function setDrawing(value) {
  return {
    type: "SET_DRAWING",
    drawing: value
  };
}

export function setAlign(value) {
  return {
    type: "SET_ALIGN",
    align: value
  };
}

export function setBold(value) {
  return {
    type: "SET_BOLD",
    isBold: value
  };
}

export function setItalic(value) {
  return {
    type: "SET_ITALIC",
    isItalic: value
  };
}

export function setUnderline(value) {
  return {
    type: "SET_UNDERLINE",
    isUnderlined: value
  };
}

export function setMenu(value) {
  return {
    type: "SET_MENU",
    menu: value
  };
}

export function setPhotoId(id) {
  return {
    type: "SET_PHOTO_ID",
    id: id
  };
}
