import { fabric } from 'fabric';
import { getMaxZ, updateJsonState, enableObjectSelection, disableObjectSelection } from '../utils/utils';
import { SELECT_TOOL, CIRCLE_TOOL } from '../utils/constants';

export function circMouseDown(options) {
  if (this.props.tool === CIRCLE_TOOL && !this.canvas.getActiveObject() && !options.e.altKey) {
    const realCoords = this.relativeMouseCoords(options);
    this.circle = new fabric.Circle({
      radius: 0,
      perPixelTargetFind: true,
      zIndex: getMaxZ.call(this) + 1,
      fill: "rgba(241,156,54,0.7)",
      left: realCoords.x,
      top: realCoords.y,
      stroke: 'black',
      strokeWidth: 2,
      hoverCursor: 'crosshair' //we want the cursor to stay a crosshair until the user finishes drawing this circle
    });
    if (this.props.lastColor) {
      this.circle.setColor(this.props.lastColor);
    }
    this.startingPoint = realCoords;
    this.canvas.add(this.circle);
  }
}

export function circMouseUp() {
  if (this.props.tool === CIRCLE_TOOL) {
    if (this.circle) {
      // When creating a new circle, make sure it is not smaller than a minimum
      // size in case a user has clicked instead of clicking and dragging
      const MIN_RADIUS = 5;
      if (this.circle.radius < MIN_RADIUS) {
        const newRad = MIN_RADIUS * 2;
        this.circle.set({ radius: newRad,
          top: this.startingPoint.y - newRad,
          left: this.startingPoint.x - newRad });
      }

      this.circle.set({ hoverCursor: "pointer" });
      //If no active object then clear group and add the circle as the active object and into the group
      this.circle.setCoords();

      this.multiObjGroup.push(this.circle);

      updateJsonState.call(this); //CALL THIS WHEN CREATING A NEW OBJECT

      if (!this.multiObjects) {
        if (this.multiObjGroup.length === 1) {
          this.canvas.setActiveObject(this.circle);
        } else {
          const selection = new fabric.ActiveSelection(this.multiObjGroup, {
            canvas: this.canvas
          });

          this.canvas.setActiveObject(selection);
        }

        this.canvas.selection = true;
        this.props.setTool(SELECT_TOOL);
        this.canvas.defaultCursor = "initial";
        this.multiObjGroup = [];
        enableObjectSelection(this.canvas);

      } else {
        //Adds additional objects and set the selection as disabled
        disableObjectSelection(this.canvas, this.canvas.defaultCursor);
      }
      this.circle = null;
      this.canvas.renderAll();

    }
  }
}

export function circMouseMove(options) {
  if (this.props.tool === CIRCLE_TOOL && this.circle) {
    const relCoords = this.relativeMouseCoords(options);
    const mouseX = relCoords.x;
    const mouseY = relCoords.y;

    var distanceX = Math.abs(mouseX - this.startingPoint.x);
    var distanceY = Math.abs(mouseY - this.startingPoint.y);
    var newRad = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));

    this.circle.set({
      radius: newRad,
      top: this.startingPoint.y - newRad,
      left: this.startingPoint.x - newRad,
    });

    this.circle.setCoords();
    this.canvas.renderAll();
  }
}
