import React from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import CustomCanvas from './utils/custom_canvas';
import { setShowSpinner, setCanvasZoom } from './reducers/actions';
import './styling.css';
import './save_or_cancel_styles.scss';

export class SaveOrCancel extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    jsonState: PropTypes.object,
    setCanvasZoom: PropTypes.func,
    setShowSpinner: PropTypes.func,
    showSpinner: PropTypes.bool,
  };

  state = {
    progress: 0
  }

  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  messageHandler = (e) => {
    if (e.data.saveInProgress) {
      this.setState({ progress: e.data.progress });
    }
  }

  formDataGenerator(file) {
    const formData = new FormData();
    formData.append('upload[file]', file);
    return formData;
  }

  cleanUpCanvas = () => {
    this.props.canvas.discardActiveObject();
    // reset panning
    this.props.canvas.viewportTransform[4] = 0;
    this.props.canvas.viewportTransform[5] = 0;

    this.props.canvas.setZoom(1);
    this.props.setCanvasZoom(this.props.canvas.getZoom());
    this.props.canvas.renderAll();
  }

  handleSaveClicked = () => {
    this.props.setShowSpinner(true);
    this.cleanUpCanvas();

    this.setState({ progress: 0 });
    window.parent.postMessage(
      {
        photoId: this.props.photoId,
        saveClicked: true,
        imgBlob: this.getImageBlob(),
        editorState: this.props.jsonState.present
      },
      '*'
    );
  }

  handleCancelClicked = () => {
    window.parent.postMessage({
      cancelClicked: true,
      photoId: this.props.photoId
    }, '*');
  }

  getImageBlob = () => {
    //Both scaleX or scaleY represents the accurate scale but FOR SAFETY MEASURES
    var scale = (this.props.canvas.backgroundImage.scaleX + this.props.canvas.backgroundImage.scaleY) / 2;

    // Scale the image up to its original size
    var modifiedImg = this.props.canvas.toDataURL({
      format: "jpeg",
      multiplier: 1 / (scale),
    });

    var imgBlob = dataURLtoBlob(modifiedImg);
    return imgBlob;
  }

  renderProgressBar = () => {
    return <ProgressBar striped animated now={this.state.progress} />;
  }

  render() {
    if (this.props.showSpinner) {
      return (
        <div>{this.renderProgressBar()}</div>
      );
    }
    return (
      <div id="lower-buttons">
        <button name="cancel-btn" className="btn btn-md cancel-btn mr-2" onClick={this.handleCancelClicked}>
          Cancel
        </button>
        <button name="save-btn" className="btn btn-md btn-secondary" onClick={this.handleSaveClicked}>
          Done Editing
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    photoId: state.photoId,
    selected: state.selected,
    jsonState: state.jsonState,
    showSpinner: state.showSpinner
  };
}
const mapDispatchToProps = { setShowSpinner, setCanvasZoom };
export default connect(mapStateToProps, mapDispatchToProps)(SaveOrCancel);

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','),
    mimeData = arr[0].match(/:(.*?);/),
    mime = mimeData ? mimeData[1] : undefined,
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
