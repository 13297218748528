import { fabric } from 'fabric';
import { getMaxZ, updateJsonState, enableObjectSelection, disableObjectSelection } from '../utils/utils';
import Polygon from '../utils/polygon';
import { SELECT_TOOL, POLY_TOOL } from '../utils/constants';


const COLOR = "rgba(24,99,163,1)";

export function setUpPolyVars() {
  this.points = [];
  this.firstPoint = true;
  adjustPolyDrawingSize.call(this);
  this.polyZoomConfigured = false;
  this.line = new fabric.Line([0, 0, 0, 0], {
    stroke: COLOR,
    zIndex: Number.MAX_SAFE_INTEGER,
    strokeWidth: this.polyDrawingLineSize,
    hasBorders: false,
    hasControls: true,
    evented: false,
    originX: 'center',
    originY: 'center'
  });
  this.drawCircles = [];
  this.newPolygon = true;
}

export function adjustPolyDrawingSize() {
  this.polyDrawingLineSize = 2 / this.canvas.getZoom();
  this.polyDrawingCircleSize = 3 / this.canvas.getZoom();
}

function mouseCloseToFirstPoint(xCor, yCor) {
  return (xCor < this.points[0].x + 5 && xCor > this.points[0].x - 5) && (yCor < this.points[0].y + 5 && yCor > this.points[0].y - 5);
}

// As the mouse moves, if the mouse gets close enough to the first point drawn
// we want to animate to the user that clicking will complete the polygon.
// This function accepts the mouse coordinates and takes care of that
function updateFirstCircleSize(xCor, yCor) {
  if (this.points.length > 1 &&
      mouseCloseToFirstPoint.call(this, xCor, yCor)) {
    this.drawCircles[0].set({
      radius: this.polyDrawingCircleSize * 2
    });
  } else {
    this.drawCircles[0].set({
      radius: this.polyDrawingCircleSize
    });
  }
}

export function polyMouseMove(options) {
  if (this.props.tool === POLY_TOOL) {
    if (this.newPolygon === true && this.firstPoint === false) {

      var realCoords = this.relativeMouseCoords(options);
      var lastX = Object.values(this.points[this.points.length - 1])[0]; // last x_coordinate plotted
      var lastY = Object.values(this.points[this.points.length - 1])[1]; // last y_coordinate plotted

      updateFirstCircleSize.call(this, realCoords.x, realCoords.y);

      this.line.set({
        x1: lastX,
        y1: lastY,
        x2: realCoords.x, // current x position of mouse
        y2: realCoords.y // current y position of mouse
      });
      if (!this.line.canvas) {
        this.canvas.add(this.line);
      }
      this.canvas.renderAll();

      this.drawCircles.push(this.line);
    }
  }
}

export function polyMouseDown(options) {
  if (this.props.tool === POLY_TOOL && this.props.drawing === true) {

    if (this.newPolygon === true && !options.e.altKey) {
      if (!this.polyZoomConfigured) {
        adjustPolyDrawingSize.call(this);
        this.polyZoomConfigured = true;
      }
      this.canvas.calcOffset();
      var realCoords = this.relativeMouseCoords(options);
      var xCor = realCoords.x;
      var yCor = realCoords.y;

      if (this.firstPoint === false && this.points.length > 1 &&
          mouseCloseToFirstPoint.call(this, xCor, yCor)) {
        generatePolygon.call(this, options);
      } else {
        this.points.push({
          x: xCor,
          y: yCor
        });
        var point = new fabric.Circle({
          radius: this.polyDrawingCircleSize,
          zIndex: Number.MAX_SAFE_INTEGER,
          fill: COLOR,
          left: xCor,
          top: yCor,
          hasControls: false,
          evented: false,
          originX: 'center',
          originY: 'center'
        });
        this.drawCircles.push(point);
        this.canvas.add(point);
        this.firstPoint = false;

      }

      this.line = new fabric.Line([0, 0, 0, 0], {
        stroke: COLOR,
        zIndex: Number.MAX_SAFE_INTEGER,
        strokeWidth: this.polyDrawingLineSize,
        hasBorders: false,
        hasControls: false,
        evented: false,
        originX: 'center',
        originY: 'center'
      });
    }
  }
}

export function generatePolygon() {
  var pol = new fabric.Polygon(this.points, {
    angle: 0,
    fill: 'rgba(24,99,163,.5)',
    hasControls: true,
    zIndex: getMaxZ.call(this) + 1,
    stroke: 'black',
    strokeWidth: 2,
    perPixelTargetFind: true,
    hoverCursor: "pointer",
    noScaleCache: false
  });

  this.canvas.add(pol);
  new Polygon(pol, this.canvas);
  this.points = [];
  this.drawCircles.forEach((element) => {
    this.canvas.remove(element);
  });
  this.drawCircles = [];
  this.multiObjGroup.push(pol);


  updateJsonState.call(this);
  this.firstPoint = true;

  if (!this.multiObjects) {
    if (this.multiObjGroup.length === 1) {
      this.canvas.setActiveObject(pol);
    } else {
      const selection = new fabric.ActiveSelection(this.multiObjGroup, {
        canvas: this.canvas
      });

      this.canvas.setActiveObject(selection);
    }

    this.props.setDrawing(false);

    this.canvas.selection = true;
    this.props.setTool(SELECT_TOOL);
    this.canvas.defaultCursor = "initial";
    this.multiObjGroup = [];
    enableObjectSelection(this.canvas);

  } else {
    //Adds additional objects and set the selection as disabled
    disableObjectSelection(this.canvas, this.canvas.defaultCursor);
  }
  this.polyZoomConfigured = false;

}

export function startNewPolygon() {
  this.newPolygon = true;
}
