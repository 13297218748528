import React from 'react';
import PropTypes from 'prop-types';
import './photo_editor/styling.css';
import PhotoEditorApp from './photo_editor/photo_editor_app';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './photo_editor/reducers/index';
import { setPhotoId } from './photo_editor/reducers/actions';

export default class PhotoEditor extends React.Component {
  static propTypes = {
    image_url: PropTypes.string.isRequired,
    photoEditState: PropTypes.string,
    photoId: PropTypes.number.isRequired,
    photoOriginalUrl: PropTypes.string,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func,
    onSave: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.store = createStore(reducer);
    this.store.dispatch(setPhotoId(props.photoId));
  }

  render() {
    return (
      <>
      <Provider store={this.store}>
        <PhotoEditorApp image_url={this.props.image_url}
          photoEditState={this.props.photoEditState} photoOriginalUrl={this.props.photoOriginalUrl} 
          onCancel={this.props.onHide} onSave={this.props.onSave}
        />
      </Provider>
      </>
    );
  }

}
