import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fabric } from 'fabric';
import CustomCanvas from './utils/custom_canvas';
import { updateJsonSliderState } from './utils/utils';
import FilterSlider from './filter_slider';
import { resetBrightness, resetSaturation, resetContrast, resetSharpen, setJsonState } from './reducers/actions';
import { Button } from 'react-bootstrap';

const DEFAULT_VAL = FilterSlider.DEFAULT_VAL;

export class FilterMenu extends React.Component {

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    filterType: PropTypes.string,
    resetBrightness: PropTypes.func,
    resetContrast: PropTypes.func,
    resetSaturation: PropTypes.func,
    resetSharpen: PropTypes.func,
    setJsonState: PropTypes.func,
    sliderBright: PropTypes.number,
    sliderContrast: PropTypes.number,
    sliderSaturation: PropTypes.number,
    sliderSharpen: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
  }

  handleClick = () => {
    var img = this.props.canvas.backgroundImage;

    if (this.props.canvas.backgroundImage.filters.length < 1) {
      //Init canvas filters
      const brightness = new fabric.Image.filters.Brightness({ brightness: 0 });
      img.filters.push(brightness);
      const saturation = new fabric.Image.filters.Saturation({ saturation: 0 });
      img.filters.push(saturation);
      const contrast = new fabric.Image.filters.Contrast({ contrast: 0 });
      img.filters.push(contrast);
      img.applyFilters();
      fabric.textureSize = fabric.textureSize * 2;
    } else { //clear filters
      this.props.resetBrightness();
      this.props.resetSaturation();
      this.props.resetContrast();
      this.props.resetSharpen();
      img.filters = [];
      img.applyFilters();
    }

    this.props.canvas.renderAll();
    updateJsonSliderState.call(this, DEFAULT_VAL, DEFAULT_VAL, DEFAULT_VAL, 0);
  }

  render() {
    return (
      <div>
        <p>
          <b>Image Settings</b>
        </p>
        {/* tools to be added - just add apply */}
        <div>
          <h5 className="pt-2"><b>Brightness</b></h5>
          <FilterSlider canvas={this.props.canvas}
            filterType="brightness"
            ref={this.brightnessSld}
            step={1}
            value={this.props.sliderBright}
          />
          <h5 className="pt-2"><b>Saturation</b></h5>
          <FilterSlider canvas={this.props.canvas}
            filterType="saturation"
            ref={this.saturationSld}
            step={1}
            value={this.props.sliderSaturation}
          />
          <h5 className="pt-2"><b>Contrast</b></h5>
          <FilterSlider canvas={this.props.canvas}
            filterType="contrast"
            ref={this.contrastSld}
            step={1}
            value={this.props.sliderContrast}
          />
          <h5 className="pt-2"><b>Sharpen</b></h5>
          <FilterSlider canvas={this.props.canvas}
            filterType="sharpen"
            ref={this.sharpenSld}
            step={25}
            value={this.props.sliderSharpen}
          />
          <div className="pt-2 filter-menu-buttons">
            <Button name="clear-filters-btn" size="sm" variant="link" 
              onClick={() => this.handleClick()}
            >
              Reset Settings
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    sliderBright: state.sliderBright,
    sliderSaturation: state.sliderSaturation,
    sliderContrast: state.sliderContrast,
    sliderSharpen: state.sliderSharpen,
    jsonState: state.jsonState,
    originalWidth: state.originalWidth,
  };
}

const mapDispatchToProps = { resetBrightness, resetSaturation, resetContrast, resetSharpen, setJsonState };

export default connect(mapStateToProps, mapDispatchToProps)(FilterMenu);
