import { MAX_ZOOM, MIN_ZOOM, SELECT_TOOL } from '../utils/constants';
import { keepImageOnScreen, setAllCoords, scaleAllControlCircles } from '../utils/utils';

export function panMouseDown(opt) {
  var evt = opt.e;
  if (evt.altKey === true) {
    this.isDragging = true;
    this.canvas.selection = false;
    this.lastPosX = evt.clientX;
    this.lastPosY = evt.clientY;

  }
}

export function panMouseUp() {
  if (this.isDragging) {
    this.isDragging = false;
    if (this.props.tool === SELECT_TOOL) {
      this.canvas.selection = true;
    }
  }
}

export function panMouseMove(opt) {
  if (this.isDragging) {
    var e = opt.e;
    this.canvas.viewportTransform[4] += e.clientX - this.lastPosX;
    this.canvas.viewportTransform[5] += e.clientY - this.lastPosY;
    this.canvas.requestRenderAll();
    this.lastPosX = e.clientX;
    this.lastPosY = e.clientY;
    keepImageOnScreen.call(this);
    setAllCoords.call(this);
  }
}

export function panMouseWheel(opt) {
  var delta = opt.e.deltaY;
  var zoom = this.canvas.getZoom();
  zoom = zoom + delta / 200;
  if (zoom > MAX_ZOOM) zoom = MAX_ZOOM;
  if (zoom < MIN_ZOOM) zoom = MIN_ZOOM;
  this.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
  opt.e.preventDefault();
  opt.e.stopPropagation();
  keepImageOnScreen.call(this);
  setAllCoords.call(this);
  scaleAllControlCircles.call(this);
  this.props.setCanvasZoom(this.canvas.getZoom());
}
