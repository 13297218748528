import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { fabric } from 'fabric';
import CustomCanvas from './utils/custom_canvas';
import { updateJsonSliderState } from './utils/utils';
import { setBrightness, setContrast, setJsonState, setSaturation, setSharpen } from './reducers/actions';
import './filter_slider_styles.scss';

export const DEFAULT_VAL = 50;

class FilterSlider extends React.Component {

  static DEFAULT_VAL = DEFAULT_VAL;

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    filterType: PropTypes.string,
    setBrightness: PropTypes.func,
    setContrast: PropTypes.func,
    setJsonState: PropTypes.func,
    setSaturation: PropTypes.func,
    setSharpen: PropTypes.func,
    sliderBright: PropTypes.number,
    sliderContrast: PropTypes.number,
    sliderSaturation: PropTypes.number,
    sliderSharpen: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
  }

  dispatchState = (val) => {
    if (!this.props.canvas) {
      return;
    }
    const img = this.props.canvas.backgroundImage;
    if (this.props.filterType === "brightness") {
      img.filters[0].brightness = (val - DEFAULT_VAL) / 100;
      this.props.setBrightness(val);
    } else if (this.props.filterType === "saturation") {
      img.filters[1].saturation = (val - DEFAULT_VAL) / 40;
      this.props.setSaturation(val);
    } else if (this.props.filterType === "contrast") {
      img.filters[2].contrast = (val - DEFAULT_VAL) / 100;
      this.props.setContrast(val);
    } else if (this.props.filterType === "sharpen") {
      var dif = val - this.props.sliderSharpen;
      if (dif < 0) {
        img.filters.splice(-1, 1); // remove last sharpen filter

      } else if (dif > 0) {
        const sharpen = new fabric.Image.filters.Convolute({
          matrix: [
            0, -1, 0,
            -1, 5, -1,
            0, -1, 0
          ]
        });
        img.filters.push(sharpen);
      }
      this.props.setSharpen(val);
    }
  }

  onSliderChange = (value) => {
    var img = this.props.canvas.backgroundImage;

    if (this.props.canvas.backgroundImage.filters.length < 1) {
      //Init canvas filters
      const brightness = new fabric.Image.filters.Brightness({ brightness: 0 });
      img.filters.push(brightness);
      const saturation = new fabric.Image.filters.Saturation({ saturation: 0 });
      img.filters.push(saturation);
      const contrast = new fabric.Image.filters.Contrast({ contrast: 0 });
      img.filters.push(contrast);

      img.applyFilters();
    }

    this.dispatchState(value);

    img.applyFilters();
    this.props.canvas.renderAll();
  }

  onAfterChange = () => {
    updateJsonSliderState.call(this,
      this.props.sliderBright,
      this.props.sliderSaturation,
      this.props.sliderContrast,
      this.props.sliderSharpen);
  }

  render() {
    return (
      <Slider className="filter-slider"
        handleStyle={{ 'borderColor': '#1863A3' }}
        step={this.props.step}
        trackStyle={{ 'backgroundColor': '#1863A3' }}
        value={this.props.value}
        onAfterChange={this.onAfterChange}
        onChange={this.onSliderChange}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    sliderBright: state.sliderBright,
    sliderSaturation: state.sliderSaturation,
    sliderContrast: state.sliderContrast,
    sliderSharpen: state.sliderSharpen,
    jsonState: state.jsonState,
    originalWidth: state.originalWidth,
  };
}

const mapDispatchToProps = { setBrightness, setContrast, setJsonState, setSaturation, setSharpen };

export default connect(mapStateToProps, mapDispatchToProps)(FilterSlider);
