import React from 'react';
import PropTypes from 'prop-types';
import CanvasWrapper from './canvas_wrapper';

class PhotoEditorApp extends React.Component {
  static propTypes = {
    image_url: PropTypes.string,
    photoEditState: PropTypes.string,
    photoOriginalUrl: PropTypes.string,
  };

  render() {
    return (
      <div className="PhotoEditorApp h-100">
        <link
          href="https://fonts.googleapis.com/css?family=Abril+Fatface|Archivo|Archivo+Narrow|Barlow|Cardo|Chivo
          |Josefin+Sans|Lato|Lora|Montserrat|Open+Sans|Open+Sans+Condensed:300|Oswald|PT+Sans|Poppins|Prata|Prompt
          |Quicksand|Raleway|Roboto|Roboto+Condensed|Slabo+27px|Source+Sans+Pro|Source+Serif+Pro&display=swap" 
          rel="stylesheet"
        />


        <CanvasWrapper image_url={this.props.image_url} photoEditState={this.props.photoEditState} 
          photoOriginalUrl={this.props.photoOriginalUrl}onCancel={this.props.onCancel}
          onSave={this.props.onSave}
        />
      </div>

    );
  }

}

export default PhotoEditorApp;
