import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fabric } from 'fabric';
import PhotoUploader from '../photo_uploader';
import { getMaxZ, updateJsonState } from "./utils/utils";
import CustomCanvas from './utils/custom_canvas';
import { setJsonState } from './reducers/actions';
import './styling.css';

class UploadWindow extends React.Component {
  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    setJsonState: PropTypes.func
  }
  constructor(props) {
    super(props);
    this._isMounted = false;
  }

  handleUpload = (url) => {

    //Receives s3 link and puts image onto canvas

    if (!url) {
      return;
    }
    fabric.Image.fromURL(url, (img) => {

      if (this._isMounted) {
        this.props.canvas.add(img);
        img.scaleToWidth(100);
        img.scaleToHeight(100);
        img.zIndex = getMaxZ.call(this) + 1;
        updateJsonState.call(this);

      }

    }, { crossOrigin: 'anonymous' });

    this.props.canvas.selection = true;
    this.props.canvas.defaultCursor = "initial";

  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  render() {
    return (
      <div>
        <h4>Upload An Image:</h4>
        <hr />
        <PhotoUploader onUpload={this.handleUpload} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    menu: state.menu,
    jsonState: state.jsonState,
  };
}
const mapDispatchToProps = { setJsonState };
export default connect(mapStateToProps, mapDispatchToProps)(UploadWindow);
