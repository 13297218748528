export const MIN_ZOOM = 0.1;
export const MAX_ZOOM = 15;

export const ARROW_TOOL = "ARROW_TOOL";
export const CIRCLE_TOOL = "CIRCLE_TOOL";
export const FILTER_TOOL = "FILTER_TOOL";
export const IMAGE_UPLOAD_TOOL = "IMAGE_UPLOAD_TOOL";
export const POLY_TOOL = "POLY_TOOL";
export const RECT_TOOL = "RECT_TOOL";
export const SELECT_TOOL = "SELECT_TOOL";
export const TEXT_TOOL = "TEXT_TOOL";

export const DRAW_MENU = "DRAW_MENU";
export const FILTER_MENU = "FILTER_MENU";
export const IMAGE_UPLOAD_MENU = "IMAGE_UPLOAD_MENU";

export const CUSTOM_ARROW = "customArrow";
export const CUSTOM_POLY = "customPoly";

export const CUSTOM_ARROW_JSON = "CustomArrowObj";
export const CUSTOM_POLY_JSON = "CustomPolyObj";
