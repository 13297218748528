import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const Select = ({ id, defaultValue, disabled, onChange, children, value }) => {
  return (
    <Form.Control
      as="select"
      defaultValue={defaultValue}
      disabled={disabled}
      id={id}
      value={value}
      onChange={onChange}
    >
      {children}
    </Form.Control>
  );
};

Select.propTypes = {
  children: PropTypes.array,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
};

export default Select;
