import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CustomCanvas from './utils/custom_canvas';
import { keepImageOnScreen, setAllCoords } from './utils/utils';

class PanButtons extends React.Component {

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
  }

  pan = (x, y) => {
    this.props.canvas.viewportTransform[4] += x;
    this.props.canvas.viewportTransform[5] += y;
    keepImageOnScreen.call(this);
    setAllCoords.call(this);
    this.props.canvas.renderAll();
  }

  render() {


    return (
      <div className="pan-controls">
        <div className="container">
          <div className="row">
            <button className="btn btn-primary  pan-button pan-button-up"
              onClick={() => this.pan(0, 50)}
            >
              <i className="fas fa-chevron-up fa-2x" />
            </button>

            <button className="btn btn-primary  pan-button pan-button-left"
              onClick={() => this.pan(50, 0)}
            >
              <i className="fas fa-chevron-left fa-2x" />
            </button>



            <button className="btn btn-primary pan-button pan-button-right"
              onClick={() => this.pan(-50, 0)}
            >
              <i className="fas fa-chevron-right fa-2x" />
            </button>

          </div>
          <div className="row">

            <button className="btn btn-primary  pan-button pan-button-down"
              onClick={() => this.pan(0, -50)}
            >
              <i className="fas fa-chevron-down fa-2x" />
            </button>

          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    canvas: state.canvas
  };
}

export default connect(mapStateToProps)(PanButtons);
