import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fabric } from 'fabric';
import classNames from 'classnames';
import { disableObjectSelection, enableObjectSelection, disableCanvasHoldClicks } from './utils/utils';
import CustomCanvas from './utils/custom_canvas';
import { setDrawing, setMenu, setTool } from './reducers/actions';
import { ARROW_TOOL, CIRCLE_TOOL, IMAGE_UPLOAD_TOOL, POLY_TOOL, RECT_TOOL, SELECT_TOOL, TEXT_TOOL } from './utils/constants';
import { IMAGE_UPLOAD_MENU } from './utils/constants';
import "./styling.css";
import ToolBarButton from "./tool_bar_button";

import {
  faDrawCircle, faMousePointer,
  faDrawSquare, faArrowRight, faDrawPolygon, faText
} from '@fortawesome/pro-solid-svg-icons';

export class ToolBar extends React.Component {

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    menu: PropTypes.string,
    selected: PropTypes.arrayOf(PropTypes.instanceOf(fabric.Object)),
    setMenu: PropTypes.func,
    setTool: PropTypes.func
  }
  closeWindow = () => {
    this.props.canvas.discardActiveObject();
    this.props.canvas.renderAll();
  }

  setTextTool = () => {
    this.closeWindow();
    this.props.canvas.selection = false;
    this.props.setTool(TEXT_TOOL);
    this.props.canvas.defaultCursor = 'text';
    disableObjectSelection(this.props.canvas, this.props.canvas.defaultCursor);
    disableCanvasHoldClicks();
  }

  setSelectTool = () => {
    this.closeWindow();
    this.props.canvas.selection = true;
    this.props.setTool(SELECT_TOOL);
    this.props.canvas.defaultCursor = 'pointer';
    enableObjectSelection(this.props.canvas);
    disableCanvasHoldClicks();
  };

  setImageUploadTool = () => {
    this.closeWindow();
    this.props.setTool(IMAGE_UPLOAD_TOOL);
    this.props.setMenu(IMAGE_UPLOAD_MENU);
    this.props.canvas.defaultCursor = 'default';
    disableCanvasHoldClicks();
  }

  setDrawTool = (toolKey) => {
    this.closeWindow();
    this.props.canvas.selection = false;
    this.props.setTool(toolKey);
    this.props.canvas.defaultCursor = 'crosshair'; //cursor for drawing polygons
    this.setState({ color: 'red' });
    disableCanvasHoldClicks();
    disableObjectSelection(this.props.canvas, this.props.canvas.defaultCursor);
  }

  setPolyToll = () => {
    this.setDrawTool('POLY_TOOL');
    this.props.setDrawing(true);
  };

  toolButtonClass = (menu) => {
    let menuActive = false;
    if (this.props.menu === menu && this.props.selected.length === 0) {
      menuActive = true;
    }
    return classNames("mr-3 btn btn-primary btn-lg tool-button general-button", { "active": menuActive });
  }

  toolButtonVariant = (tool) => {
    let toolActive = false;
    if (this.props.tool === tool && this.props.selected.length === 0) {
      toolActive = true;
    }
    return toolActive ? 'primary' : 'outline-dark';
  }

  render() {
    return (
      <div className="d-flex flex-column">
        <div className={'pb-3'}>
          <ToolBarButton name="select-tool-btn"  variant={this.toolButtonVariant(SELECT_TOOL)} icon={faMousePointer}
            onClick={this.setSelectTool} />
        </div>
        <ToolBarButton name="circle-tool-btn" variant={this.toolButtonVariant(CIRCLE_TOOL)} icon={faDrawCircle}
          onClick={() => this.setDrawTool(CIRCLE_TOOL)} />
        <ToolBarButton name="rect-tool-btn"  variant={this.toolButtonVariant(RECT_TOOL)} icon={faDrawSquare}
          onClick={() => this.setDrawTool(RECT_TOOL)} />
        <ToolBarButton name="poly-tool-btn"  variant={this.toolButtonVariant(POLY_TOOL)} icon={faDrawPolygon}
          onClick={this.setPolyToll} />
        <ToolBarButton name="arrow-tool-btn"  variant={this.toolButtonVariant(ARROW_TOOL)} icon={faArrowRight}
          onClick={() => this.setDrawTool(ARROW_TOOL)} />
        <div className={'pt-3'}>
          <ToolBarButton name="text-tool-btn"  variant={this.toolButtonVariant(TEXT_TOOL)} icon={faText}
            onClick={this.setTextTool} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    menu: state.menu
  };
}
const mapDispatchToProps = { setMenu, setTool, setDrawing };
export default connect(mapStateToProps, mapDispatchToProps)(ToolBar);
