import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './tool_bar_button_styles.scss';

const ToolBarButton = ({ icon, onClick, variant }) => {
  return <Button className="tool-bar-button" onClick={onClick} variant={variant}>
    <FontAwesomeIcon size={'2x'} className="align-middle" icon={icon} />
  </Button >
};

export default ToolBarButton;
