import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHorizontalRule } from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowRight } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import CustomCanvas from './utils/custom_canvas';
import { updateJsonState } from './utils/utils';
import { setJsonState, setLastColor } from './reducers/actions';
import ColorPicker from './inputs/color_picker/color_picker';

export class ArrowEditControls extends React.Component {

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    popupOpen: PropTypes.bool,
    selected: PropTypes.array,
    setJsonState: PropTypes.func,
    setLastColor: PropTypes.func
  }

  state = { thickness: 0, lineColor: 'black', arrowShown: true };
  componentDidMount() {
    if (this.props.selected[0]) {
      const arrowObj = this.props.selected[0].arrowObj;
      this.setState({ arrowShown: arrowObj.triVisible });
      this.setState({ thickness: arrowObj.strokeWidth });
      this.setState({ lineColor: arrowObj.line.stroke });
    }
  }

  onColorChange = (color) => {
    if (this.props.selected.length === 0) {
      return;
    }
    const c = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    this.getUniqueArrowObjs().forEach((arrow) => {
      arrow.setColor(c);
    });
    this.setState({ lineColor: c });
    this.props.setLastColor(this.state.lineColor);

    this.props.canvas.renderAll();
  }

  getUniqueArrowObjs = () => {
    const arrowSet = new Set();
    for (const obj of this.props.selected) {
      if (!arrowSet.has(obj.arrowObj)) {
        arrowSet.add(obj.arrowObj);
      }
    }
    return arrowSet;
  }

  setArrowShown = (arrowShown) => {
    this.setState({ arrowShown: arrowShown });
    this.getUniqueArrowObjs().forEach(arrow => arrow.setTriangleShown(arrowShown));
    updateJsonState.call(this);
  }
  setThickness = (thickness) => {
    this.setState({ thickness: thickness });
    this.getUniqueArrowObjs().forEach(arrow => arrow.setThickness(thickness));
    if (thickness < 2) {
      this.getUniqueArrowObjs().forEach(arrow => arrow.setTriangleFactor(5));
    } else {
      this.getUniqueArrowObjs().forEach(arrow => arrow.setTriangleFactor(3.5));
    }
  }


  onSliderChange = (value) => {
    const newThick = value / 10 + 0.5;
    this.setThickness(newThick);
    this.props.canvas.renderAll();
  }

  onAfterSliderChange = () => {
    updateJsonState.call(this);
  }

  isArrowShown = () => {
    return this.getUniqueArrowObjs().values().next().value.tri.visible;
  }

  arrowButtonClass = () => {
    return classNames("btn text-format-btn force-width", { "active": !this.isArrowShown() });
  }

  lineButtonClass = () => {
    return classNames("btn text-format-btn force-width", { "active": this.isArrowShown() });
  }

  render() {
    return (
      <div className="mb-2">
        <h5><b>Color</b></h5>
        <ColorPicker
          name="colorpicker-btn"
          value={this.state.lineColor}
          onChange={this.onColorChange}
        />
        <h5>Line Thickness: </h5>
        <Slider className="filter-slider" 
          disabled={this.props.popupOpen}
          handleStyle={{ 'borderColor': '#1863A3' }}
          trackStyle={{ 'backgroundColor': '#1863A3' }}
          value={(this.state.thickness - 0.5) * 10}
          onAfterChange={this.onAfterSliderChange}
          onChange={this.onSliderChange}
        />
        <h5>Line Style: </h5>
        <div>
          <button name="line-mode-btn" className={this.arrowButtonClass()} onClick={() => this.setArrowShown(false)}>
            <FontAwesomeIcon className="fa-lg format-text-icons" icon={faHorizontalRule} />
          </button>
          <button name="arrow-mode-btn" className={this.lineButtonClass()} onClick={() => this.setArrowShown(true)}>
            <FontAwesomeIcon className="fa-lg format-text-icons" icon={faLongArrowRight} />
          </button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selected: state.selected,
    canvas: state.canvas,
    jsonState: state.jsonState,
    popupOpen: state.popupOpen
  };
}

const mapDispatchToProps = { setJsonState, setLastColor };

export default connect(mapStateToProps, mapDispatchToProps)(ArrowEditControls);
