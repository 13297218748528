import React from 'react';
import PropTypes from 'prop-types';
import ImageDropzone from './image_dropzone';


class PhotoUploader extends React.Component {
  static propTypes = {
    onUpload: PropTypes.func.isRequired
  }

  handlePhotoUpload = (url) => {
    this.props.onUpload(url);
  }
  
  dropBox = (
    <div style={ { padding: "1em", border: "1px dashed #e1dede", cursor: "pointer" } }>
      <h4 className="text-center dz-message">
        <i className="far fa-image text-muted pr-1"/>
        <span className="text-muted">
          Drag files, or click here to add files
        </span>
      </h4>
    </div>
  )

  render() {
    return (
      <div>
        <ImageDropzone
          onUploadComplete={this.handlePhotoUpload}
          dropBox={this.dropBox}
          dropZoneClassName="media-uploader"
          disablePreview
          progress={this.props.progress}
        />
      </div>
    );
  }
}

export default PhotoUploader;
