import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { updateJsonState } from "./utils/utils";
import CustomCanvas from './utils/custom_canvas';
import { setAlign, setBold, setItalic, setJsonState,
  setLastColor, setTextEdit, setUnderline } from './reducers/actions';
import './styling.css';
import Select from './inputs/select';
import ColorPicker from './inputs/color_picker/color_picker';
import { ButtonGroup, Button } from 'react-bootstrap';
import { loadFont } from './tools/text_tool.js';

export class TextEditWindow extends React.Component {

  static propTypes = {
    activeFontFamily: PropTypes.string,
    canvas: PropTypes.instanceOf(CustomCanvas),
    popupOpen: PropTypes.bool,
    selected: PropTypes.array,
    setAlign: PropTypes.func,
    setBold: PropTypes.func,
    setItalic: PropTypes.func,
    setJsonState: PropTypes.func,
    setLastColor: PropTypes.func,
    setTextEdit: PropTypes.func,
    setUnderline: PropTypes.func,
  }
  constructor(props) {
    super(props);
    this.fonts = ["Times", "Roboto", "Open Sans", "Open Sans Condensed", "Lato", "Slabo 27px", "Oswald",
      "Source Sans Pro", "Source Serif Pro", "Montserrat", "Raleway", "PT Sans", "Lora", "Prompt", "Cardo",
      "Quicksand", "Abril Fatface", "Archivo Narrow", "Archivo", "Prata", "Chivo", "Josefin Sans", "Poppins",
      "Barlow"];
    this.textSizes = [8, 9, 10, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96];
  }
  state = {
    hasPopoverOpen: false,
    bgColor: 'white',
    fontColor: 'black',
    value: "placeholder",
  }

  componentDidMount() {
    // add fonts to dropdown
    this.fonts.forEach(function (font) {
      loadFont(font);
    });

    // set up dropdown menu
    this.setState({ align: this.props.selected[0].textAlign });

    // this.updateMenu(); // run once on load
    this.props.canvas.on('selection:updated', this.updateMenu); // run on selection change
    this.setState({ bgColor: this.props.selected[0].backgroundColor });
    this.setState({ fontColor: this.props.selected[0].fill });
  }

  changeActiveObjectFont(e) { // update font on selected textbox based on edit window
    for (const obj of this.props.selected) {
      obj.fontFamily = e.target.value;
    }
    this.props.canvas.renderAll();
    updateJsonState.call(this);

  }

  changeActiveObjectFontSize(e) { // update font size on selected textbox based on edit window
    for (const obj of this.props.selected) {
      obj.fontSize = e.target.value;
      obj.setCoords();
    }
    this.props.canvas.renderAll();
    updateJsonState.call(this);

  }

  getRGBA = (color) => {
    return `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
  }

  onBackgroundColorChange = (color) => {
    if (this.props.selected.length === 0) {
      return;
    }

    const rgbaColor = this.getRGBA(color);
    for (const obj of this.props.selected) {
      obj.set({ backgroundColor: rgbaColor });
    }
    this.setState({ bgColor: rgbaColor });
    this.props.setLastColor(this.state.bgColor);

    this.props.canvas.renderAll();
  }

  onFontColorChange = (color) => {
    if (this.props.selected.length === 0) {
      return;
    }

    const rgbaColor = this.getRGBA(color);
    for (const obj of this.props.selected) {
      obj.set({ fill: rgbaColor });
    }
    this.setState({ fontColor: rgbaColor });
    this.props.setLastColor(this.state.fontColor);

    this.props.canvas.renderAll();
  }

  alignText = (alignment) => {
    for (const obj of this.props.selected) {
      obj.set({ textAlign: alignment });
    }
    this.props.setAlign(alignment);
    this.props.canvas.renderAll();
    updateJsonState.call(this);
  }

  styleText = (style) => {
    //Only detects first object for button looks but updates all textboxes
    if (style === 'bold') {
      if (this.props.selected[0].fontWeight === style) {
        for (const obj of this.props.selected) {
          if (obj.fontWeight === style) {
            obj.set({ fontWeight: 'normal' });
          }
        }
        this.props.setBold(false);

      } else {
        for (const obj of this.props.selected) {
          if (obj.fontWeight !== style) {
            obj.set({ fontWeight: style });
          }
        }
        this.props.setBold(true);
      }

    }
    if (style === 'italic') {
      if (this.props.selected[0].fontStyle === 'italic') {
        for (const obj of this.props.selected) {
          if (obj.fontStyle) {
            delete obj.fontStyle;
          }
        }
        this.props.setItalic(false);
      } else {
        for (const obj of this.props.selected) {
          if (obj.fontStyle) {
            obj.set({ fontStyle: style });
          }
        }
        this.props.setItalic(true);
      }
    }
    if (style === 'underline') {
      if (this.props.selected[0].underline === true) {
        for (const obj of this.props.selected) {
          if (obj.underline) {
            obj.set({ underline: false });
          }
        }
        this.props.setUnderline(false);
      } else {
        for (const obj of this.props.selected) {
          if (!obj.underline) {
            obj.set({ underline: true });
          }
        }
        this.props.setUnderline(true);
      }
    }
    this.props.canvas.renderAll();
    updateJsonState.call(this);
  }

  textEditState(e, value) {
    this.props.setTextEdit(value);
    //On exit -> update redux state
    if (!value) {
      updateJsonState.call(this);
    }
  }

  boldButtonClass = () => {
    return classNames("btn text-format-btn", { "active": this.props.selected[0].fontWeight === "bold" });
  }

  italicButtonClass = () => {
    return classNames("btn text-format-btn", { "active": this.props.selected[0].fontStyle === "italic" });
  }

  underlineButtonClass = () => {
    return classNames("btn text-format-btn", { "active": this.props.selected[0].underline });
  }

  alignButtonClass = (alignment) => {
    return classNames("btn text-format-btn", { "active": alignment === this.props.selected[0].textAlign });
  }

  renderFontColor = () => {
    return <div className="row flex-column">
      <h5><b>Font Color</b></h5>
      <ColorPicker 
        disabled={this.state.hasPopoverOpen}
        name="font-color-btn"
        value={this.props.selected[0].fill}
        onChange={this.onFontColorChange}
        onClose={() => this.setState({ hasPopoverOpen: false })}
        onOpen={() => this.setState({ hasPopoverOpen: true })}
      />
    </div>;
  }

  renderBackgroundColor = () => {
    return <div className="row flex-column">
      <h5><b>Background Color</b></h5>
      <ColorPicker
        disabled={this.state.hasPopoverOpen}
        name="background-color-btn"
        value={this.props.selected[0].backgroundColor}
        onChange={this.onBackgroundColorChange}
        onClose={() => this.setState({ hasPopoverOpen: false })}
        onOpen={() => this.setState({ hasPopoverOpen: true })}
      />
    </div>;
  }

  renderFontPicker = () => {
    const obj = this.props.canvas.getActiveObjects()[0];

    return (
      <>
        <div className="row">
          <h5><b>Font</b></h5>
          <Select disabled={this.props.popupOpen} id="font-family" value={obj.fontFamily}
            onChange={e => this.changeActiveObjectFont(e)}
          >
            {this.fonts.map((font, index) => <option key={index} value={font}>{font}</option>)}
          </Select>
        </div>
      </>
    );
  }

  renderFontSizePicker = () => {
    const obj = this.props.canvas.getActiveObjects()[0];

    return (
      <div className="flex-column flex-grow-1 mr-3">
        <h5><b>Font Size</b></h5>
        <Select disabled={this.props.popupOpen} name="font-size" value={Number(obj.fontSize)}
          onChange={e => this.changeActiveObjectFontSize(e)}
        >
          {this.textSizes.map(textSize => <option key={textSize} value={textSize}>{textSize}</option>)}
        </Select>
      </div>
    );
  }

  renderAlignmentControls = () => {
    const align = this.props.selected[0].textAlign;

    return (
      <div className="row flex-column text-align-controls">
        <h5><b>Align</b></h5>
        <ButtonGroup>
          <Button className={align === 'left' ? '' : 'unselected-btn'} disabled={this.props.popupOpen}
            name="align-left-btn" size="sm" variant="primary"
            onClick={() => this.alignText('left')}
          >
            <i className="fas fa-lg fa-align-left format-text-icons" />
          </Button>
          <Button className={align === 'center' ? '' : 'unselected-btn'} disabled={this.props.popupOpen}
            name="align-center-btn" size="sm" variant="primary"
            onClick={() => this.alignText('center')}
          >
            <i className="fas fa-lg fa-align-center format-text-icons" />
          </Button>
          <Button className={align === 'right' ? '' : 'unselected-btn'} disabled={this.props.popupOpen}
            name="align-right-btn" size="sm" variant="primary"
            onClick={() => this.alignText('right')}
          >
            <i className="fas fa-lg fa-align-right format-text-icons" />
          </Button>
          <Button className={align === 'justify' ? '' : 'unselected-btn'} disabled={this.props.popupOpen} 
            name="align-justify-btn" size="sm" variant="primary"
            onClick={() => this.alignText('justify')}
          >
            <i className="fas fa-lg fa-align-justify format-text-icons" />
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  // bold, italic, and underline options
  renderTextFormatControls = () => {
    const isBold = this.props.selected[0].fontWeight === 'bold';
    const isItalic = this.props.selected[0].fontStyle === 'italic';
    const isUnderlined = this.props.selected[0].underline;

    return (
      <div className="text-format-controls">
        <h5><b>Format</b></h5>
        <ButtonGroup>
          <Button className={isBold ? '' : 'unselected-btn'} disabled={this.props.popupOpen}
            name="bold-btn"
            size="sm" variant="primary"
            onClick={() => this.styleText('bold')}
          >
            <i className="fa fa-lg fa-bold format-text-icons" />
          </Button>
          <Button className={isItalic ? '' : 'unselected-btn'} disabled={this.props.popupOpen}
            name="italic-btn"
            size="sm" variant="primary"
            onClick={() => this.styleText('italic')}
          >
            <i className="fa fa-lg fa-italic format-text-icons" />
          </Button>
          <Button className={isUnderlined ? '' : 'unselected-btn'} disabled={this.props.popupOpen}
            name="underline-btn"
            size="sm" variant="primary"
            onClick={() => this.styleText('underline')}
          >
            <i className="fa fa-lg fa-underline format-text-icons" />
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid mb-3" id="text-edit">
        {this.renderFontPicker()}
        {this.renderFontColor()}
        <div className="row">
          {this.renderFontSizePicker()}
          {this.renderTextFormatControls()}
        </div>
        {this.renderAlignmentControls()}
        {this.renderBackgroundColor()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    activeFontFamily: state.activeFontFamily,
    fontSize: state.fontSize,
    jsonState: state.jsonState,
    popupOpen: state.popupOpen,
    textIsEditing: state.textIsEditing,
    align: state.align,
    isBold: state.isBold,
    isItalic: state.isItalic,
    isUnderlined: state.isUnderlined
  };
}
const mapDispatchToProps = { setAlign, setBold, setItalic, setJsonState, setLastColor, setTextEdit, setUnderline };
export default connect(mapStateToProps, mapDispatchToProps)(TextEditWindow);
