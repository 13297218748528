import { fabric } from 'fabric';

// fabric.js Canvas with improved functionality (double click events, z-indexing, and improved stroke rendering)
class CustomCanvas extends fabric.Canvas {
  constructor(...args) {
    super(...args);
    this.enableDoubleClicks();
    this.fixStrokeScaling();
  }

  // add functionality for fabric objects to have double click events
  enableDoubleClicks = () => {
    // based off https://stackoverflow.com/questions/23418055/fabricjs-double-click-on-objects
    fabric.util.addListener(this.upperCanvasEl, 'dblclick', (e) => {
      var target = this.findTarget(e);
      if (target) {
        target.fire('dblclick', { e: e });
      }
    });
  }

  // patch fabric to correctly render strokes for grouped (and scaled) objects
  fixStrokeScaling = () => {
    // inspired by:
    // https://stackoverflow.com/questions/39548747/fabricjs-how-to-scale-object-but-keep-the-border-stroke-width-fixed
    fabric.Object.prototype._renderStroke = function (ctx) {
      if (!this.stroke || this.strokeWidth === 0) {
        return;
      }
      if (this.shadow && !this.shadow.affectStroke) {
        this._removeShadow(ctx);
      }
      ctx.save();
      if (this.group) {
        ctx.scale(1 / this.group.scaleX, 1 / this.group.scaleY);
      } else {
        ctx.scale(1 / this.scaleX, 1 / this.scaleY);
      }
      this._setLineDash(ctx, this.strokeDashArray, this._renderDashedStroke);
      this._applyPatternGradientTransform(ctx, this.stroke);
      ctx.stroke();
      ctx.restore();
    };
  }

  // override the rendering order of objects to enable proper z-indexing
  _chooseObjectsToRender(...args) {
    let objsToRender = super._chooseObjectsToRender(args);
    //from https://stackoverflow.com/questions/14131206/control-z-index-in-fabric-js
    objsToRender = objsToRender.sort(function (a, b) {
      var sortValue = 0, az = a.zIndex || 0, bz = b.zIndex || 0;
      if (az < bz) {
        sortValue = -1;
      } else if (az > bz) {
        sortValue = 1;
      }

      return sortValue;
    });
    return objsToRender;
  }
}

export default CustomCanvas;
