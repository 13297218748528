import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CustomCanvas from './utils/custom_canvas';
import { deleteObjects } from "./utils/utils";
import TextEditWindow from './text_edit_window';
import ArrowEditControls from './arrow_edit_controls';
import ShapeEditControls from './shape_edit_controls';
import LayerControlButtons from './layer_control_buttons';
import { setJsonState } from './reducers/actions';
import { CUSTOM_ARROW, CUSTOM_POLY } from './utils/constants';
import './styling.css';
import 'rc-slider/assets/index.css';


const FABRIC_TYPES = { "rect": "shape", "polygon": "shape", "circle": "shape", CUSTOM_POLY: "shape" };

export class EditWindow extends React.Component {

  static propTypes = {
    canvas: PropTypes.instanceOf(CustomCanvas),
    selectionChanged: PropTypes.bool,
    setJsonState: PropTypes.func
  }

  state = {
    selectedColor: "#0EAA0A",
    fillClicked: false,
    strokeClicked: false,
    value: this.props.canvas.getActiveObjects()[0].strokeWidth * 5
  }

  // It looks like this function may never be called.
  // If that's the case, mapDispatchToProps can be removed for this component
  deleteObjects = () => {
    deleteObjects.call(this);
    return;
  };

  getMappedType = (fabricObj) => {
    let type = fabricObj.customType || fabricObj.__proto__.type;
    type = FABRIC_TYPES[type] ? FABRIC_TYPES[type] : type;
    return type;
  }
  selectedAllSameType = () => {
    if (this.props.canvas.getActiveObjects().length === 0) {
      return false;
    }
    if (this.props.canvas.getActiveObjects().length === 1) {
      return true;
    }
    const type = this.getMappedType(this.props.canvas.getActiveObjects()[0]);
    for (const obj of this.props.canvas.getActiveObjects()) {
      const tempType = this.getMappedType(obj);
      if (tempType !== type) {
        return false;
      }
    }
    return true;
  }


  getButtonsForSelection = () => {
    return <LayerControlButtons />;
  }

  closeWindow = () => {
    this.props.canvas.discardActiveObject();
    this.props.canvas.renderAll();
  }

  getControlsForSelection = () => {
    if (this.selectedAllSameType()) {
      const type = this.getMappedType(this.props.canvas.getActiveObjects()[0]);
      if (type === CUSTOM_ARROW) {
        return <ArrowEditControls key={this.props.selectionChanged} />;
      } else if (type === "shape" || type === CUSTOM_POLY) {
        return (<ShapeEditControls />);
      } else if (type === "textbox") {
        return <TextEditWindow key={this.props.selectionChanged} />;
      }
    }
  }


  render() {
    return (
      <div className="popup">
        <div className="custom-buttons" id="edit-window-wrapper">
          <div className="close-edit-window w-100" >
            <p>
              <b>Edit Selection</b>
              <button className="float-right" id="exit-button" onClick={this.closeWindow}>
                <i className="fas fa-times"></i>
              </button>
            </p>

          </div>

          {this.getControlsForSelection()}
          {this.getButtonsForSelection()}

        </div>
        <hr />
      </div>
    );
  }

}
function mapStateToProps(state) {
  return {
    tool: state.tool,
    canvas: state.canvas,
    selected: state.selected,
    jsonState: state.jsonState,
    color: state.color,
    toBeColored: state.toBeColored,
    selectionChanged: state.selectionChanged,
    popupOpen: state.popupOpen
  };
}
const mapDispatchToProps = { setJsonState };
export default connect(mapStateToProps, mapDispatchToProps)(EditWindow);
