import { fabric } from 'fabric';
import { getMaxZ, updateJsonState, enableObjectSelection, disableObjectSelection } from '../utils/utils';
import { SELECT_TOOL, TEXT_TOOL } from '../utils/constants';


export function textMouseDown(options) {
  if (this.props.tool === TEXT_TOOL && !this.canvas.getActiveObject()) {

    if (options.e.altKey) {
      return;
    }
    const realCoords = this.relativeMouseCoords(options);
    this.textbox = new fabric.Textbox("", {
      width: 0,
      textAlign: 'center',
      height: 0,
      zIndex: getMaxZ.call(this) + 1,
      fontSize: this.props.fontSize,
      fontFamily: this.props.activeFontFamily,
      lockScalingY: true,
      left: realCoords.x,
      top: realCoords.y,
      text: this.props.text,
      backgroundColor: 'rgba(255,255,255,0.65)',
      editable: true,
    });
    //Set controls visibility
    const ctrlList = ['bl', 'br', 'mb', 'mt', 'tl', 'tr']; //Everything besides rotating point - mtr, ml, mr
    for (const point of ctrlList) {
      this.textbox.setControlVisible(point, false);
    }

    this.startingPoint = realCoords;
    this.canvas.add(this.textbox);
  }
}

export function loadFont(font) {
  var FontFaceObserver = require('fontfaceobserver');
  var currentFont = new FontFaceObserver(font);
  if (font !== 'Times') { // do not attempt to load Times -- not a google font
    currentFont.load();
  }
}

export function textMouseUp(options) {
  if (this.props.tool === TEXT_TOOL && !this.canvas.getActiveObject() && !options.e.altKey) {
    if (this.textbox) {
      this.textbox.set({ hoverCursor: "pointer" });
      //Resize to 100 height, 200 width if width and height is 0
      if (this.textbox.width < 4) {
        this.textbox.width = 200;
        this.textbox.setCoords();
      }
      this.multiObjGroup.push(this.textbox);

      updateJsonState.call(this);
    }
    this.canvas.renderAll();
    if (!this.multiObjects) {
      if (this.multiObjGroup.length === 1) {
        this.canvas.setActiveObject(this.textbox);
        this.textbox.enterEditing();
      } else {
        const selection = new fabric.ActiveSelection(this.multiObjGroup, {
          canvas: this.canvas
        });

        this.canvas.setActiveObject(selection);
      }

      this.canvas.selection = true;
      this.props.setTool(SELECT_TOOL);
      this.canvas.defaultCursor = "initial";
      this.multiObjGroup = [];
      enableObjectSelection(this.canvas);

    } else {
      //Adds additional objects and set the selection as disabled
      disableObjectSelection(this.canvas, this.canvas.defaultCursor);
    }
    this.textbox = null;
    this.canvas.renderAll();

  }
}


export function textMouseMove(options) {
  if (this.props.tool === TEXT_TOOL && this.textbox) {
    const relCoords = this.relativeMouseCoords(options);
    const mouseX = relCoords.x;
    const mouseY = relCoords.y;
    var newTop, newLeft;
    if (mouseX > this.startingPoint.x) {
      newLeft = this.startingPoint.x;
    } else {
      newLeft = mouseX;
    }
    if (mouseY > this.startingPoint.y) {
      newTop = this.startingPoint.y;
    } else {
      newTop = mouseY;
    }

    this.textbox.set({
      width: Math.abs(mouseX - this.startingPoint.x),
      height: Math.abs(mouseY - this.startingPoint.y),
      top: newTop,
      left: newLeft
    });
    this.textbox.padding = 5;
    this.textbox.setCoords();
    this.canvas.renderAll();
  }
}
