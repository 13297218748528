import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

import Dropzone from 'react-dropzone';
import { ProgressBar } from 'react-bootstrap';

import './styles/image_dropzone.scss';

const defaultUploadPath = '/uploads';

class ImageDropzone extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disableClick: PropTypes.bool,
    dropBox: PropTypes.node,
    dropZoneClassName: PropTypes.string,
    formDataGenerator: PropTypes.func,
    photoId: PropTypes.number,
    preview: PropTypes.string,
    uploadId: PropTypes.number,
    uploadPath: PropTypes.string,
    onDelete: PropTypes.func,
    onUploadComplete: PropTypes.func.isRequired
  }

  componentDidMount() {
    window.addEventListener('message', this.messageHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageHandler);
  }

  static defaultProps = {
    formDataGenerator(file) {
      const formData = new FormData();
      formData.append('upload[file]', file);
      return formData;
    },

    uploadPath: defaultUploadPath
  }

  static defaultUploadPath = defaultUploadPath

  state = {
    uploading: false
  }

  messageHandler = (e) => {
    if (e.data.uploadCompleted) {
      this.setState({ uploading: false });
      this.props.onUploadComplete(e.data.url.url);
      this.handleDrop(this.files);
    } else if (e.data.uploadInProgress) {
      this.setState({ progress: e.data.uploadInProgress });
    }
  }

  uploadFile(file) {
    window.parent.postMessage({
      uploadRequested: true,
      photoId: this.props.photoId,
      file: file
    }, '*');
  }

  handleDrop = (files) => {
    this.files = files;
    if (this.files.length === 0) {
      this.setState({ progress: 0, uploading: false });
      return;
    }
    const file = this.files.shift();
    this.setState({ progress: 0, uploading: true });
    this.uploadFile(file)
  }

  render() {
    const {
      className,
      dropZoneClassName,
      /* eslint-disable no-unused-vars */
      formDataGenerator,
      uploadPath,
      onDelete,
      onUploadComplete,
      dropBox,
      uploadId,
      /* eslint-enable no-unused-vars */
      ...dropzoneProps
    } = this.props;

    return (
      <div className={`image-dropzone ${className || ''}`}>
        <Dropzone
          accept="image/*, application/pdf"
          onDrop={this.handleDrop}
          {...dropzoneProps}
        >
          {({ getRootProps, getInputProps }) => (
            <div className={dropZoneClassName} {...getRootProps()}>
              {this.state.uploading ? this.renderProgressBar() : this.renderPreview()}
              <input {...getInputProps()} />
            </div>
          )}
        </Dropzone>
        {this.props.preview && this.renderDeleteButton()}
      </div>
    );
  }

  renderDeleteButton() {
    if (!this.props.onDelete) {
      return null;
    }

    return (
      <button
        className="btn btn-xs btn-danger delete-file"
        data-upload-id={this.props.uploadId}
        onClick={this.props.onDelete}
      >
        <FontAwesomeIcon className="align-middle" icon={faTrash}/>
      </button>
    );
  }

  renderPreview() {
    if (this.props.preview) {
      return <img src={this.props.preview} />;
    } else if (this.props.dropBox) {
      return this.props.dropBox;
    } else {
      return <h4 className="text-muted text-center">Click or drag files here to upload</h4>;
    }
  }

  renderProgressBar() {
    return <ProgressBar striped animated now={this.state.progress} />;
  }
}

function mapStateToProps(state) {
  return {
    photoId: state.photoId
  };
}

export default connect(mapStateToProps)(ImageDropzone);
