import { fabric } from 'fabric';
import { getMaxZ, updateJsonState, enableObjectSelection, disableObjectSelection } from '../utils/utils';
import { SELECT_TOOL, RECT_TOOL } from '../utils/constants';

export function rectMouseDown(options) {
  if (this.props.tool === RECT_TOOL && !this.canvas.getActiveObject() && !options.e.altKey) {
    const realCoords = this.relativeMouseCoords(options);
    //Disable canvas selection just in case

    this.rect = new fabric.Rect({
      width: 0,
      height: 0,
      zIndex: getMaxZ.call(this) + 1,
      fill: 'rgba(24,99,163,.7)',
      left: realCoords.x,
      top: realCoords.y,
      stroke: 'black',
      strokeWidth: 2,
      hoverCursor: 'crosshair', //we want the cursor to stay a crosshair until the user finishes drawing this rectangle
      noScaleCache: false
    });
    if (this.props.lastColor) {
      this.rect.setColor(this.props.lastColor);
    }
    this.startingPoint = realCoords;
    this.canvas.add(this.rect);

  }
}

export function rectMouseUp() {
  if (this.props.tool === RECT_TOOL) {
    if (this.rect) {
      // When creating a new shape, make sure it is not smaller than a minimum
      // size in case a user has clicked instead of clicking and dragging
      const MIN_DIM = 10;
      const NEW_DIM = 40;
      if (this.rect.width < MIN_DIM && this.rect.height < MIN_DIM) {
        this.rect.set({ width: NEW_DIM, height: NEW_DIM });
      }
      this.rect.set({ hoverCursor: "pointer" });
      this.rect.setCoords();

      //If no active object then clear group and add the rect as the active object and into the group
      this.multiObjGroup.push(this.rect);


      updateJsonState.call(this); //CALL THIS WHEN CREATING A NEW OBJECT

      if (!this.multiObjects) {
        if (this.multiObjGroup.length === 1) {
          this.canvas.setActiveObject(this.rect);
        } else {
          const selection = new fabric.ActiveSelection(this.multiObjGroup, {
            canvas: this.canvas
          });

          this.canvas.setActiveObject(selection);
        }
        //Should also select object automatically on mouseup for last object
        this.canvas.selection = true;
        this.props.setTool(SELECT_TOOL);
        this.canvas.defaultCursor = "initial";
        this.multiObjGroup = [];

      } else {
        //Adds additional objects and set the selection as disabled
        disableObjectSelection(this.canvas, this.canvas.defaultCursor);
      }
      this.rect = null;
      enableObjectSelection(this.canvas);

    }
    this.canvas.renderAll();

  }
}

export function rectMouseMove(options) {
  if (this.props.tool === RECT_TOOL && this.rect) {
    const relCoords = this.relativeMouseCoords(options);
    const mouseX = relCoords.x;
    const mouseY = relCoords.y;
    var newTop, newLeft;
    if (mouseX > this.startingPoint.x) {
      newLeft = this.startingPoint.x;
    } else {
      newLeft = mouseX;
    }
    if (mouseY > this.startingPoint.y) {
      newTop = this.startingPoint.y;
    } else {
      newTop = mouseY;
    }

    this.rect.set({
      width: Math.abs(mouseX - this.startingPoint.x),
      height: Math.abs(mouseY - this.startingPoint.y),
      top: newTop,
      left: newLeft
    });
    this.rect.setCoords();
    this.canvas.renderAll();
  }
}
